.cardDetailsContact {
    position: fixed;
    width: 400px;
    min-height: 40px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
}

.cardDetailsContact .lineCardDetailsContact {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .initialsNameDetailsContact {
    color: var(--color-information);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background-color: #c2dfff;
    border-radius: 50%;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact {
    display: flex;
    align-items: flex-start;
    vertical-align: middle;
    padding-left: 5px;
    overflow: hidden;
    flex-direction: column;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .iconDetailsContact{
    color: var(--dark-dark-three);
    font-size: 12px;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact span{
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 400;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact {
    font-size: 20px;
    padding-left: 0;
    margin-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start;
    display: flex;
    width: 100%;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}