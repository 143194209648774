.dashboardProspection {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboardProspection .click {
    cursor: pointer;
}

.dashboardProspection .buttonToLoaderTablesDashboardProspection {
    width: 120px;
    font-size: 16px;
    padding: 8px 12px;
    margin: 10px auto;
    font-weight: 400;
}

.dashboardProspection .headerDashboardProspection {
    /* nothing */
}

.dashboardProspection .buttonTodayDashboardProspection {
    width: 50px;
    height: 30px;
    color: var(--color-success);
    background-color: transparent!important;
    border: 1px solid var(--color-success);
    font-size: 10px;
    margin: 0 5px;
    border-radius: 2px;
    padding: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardProspection .containerBodyDashboardProspection {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection {
    margin: 20px 20px 0 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    transform: rotate(45deg);
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection .boxHeaderDashboardProspection {
    z-index: inherit;
    width: 100%;
    margin-bottom: 10px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
    z-index: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    z-index: inherit;
    margin: 20px 20px 0 20px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .iconFilterCompanyUnitDashboardProspection {
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection {
    cursor: pointer;
    width: 100%;
    border: none;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .fieldSelectMulti {
    border: none;
    box-shadow: none;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .optionsSelectMulti {
    z-index: 200;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .checkSpecificPeriodDashboardProspection {
    max-width: 220px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .buttonSearchReportDashboardProspection {
    background-color: transparent!important;
    color: var(--color-success);
    height: inherit;
    margin-left: 10px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputFilterDashboardProspection {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputTimeFilterDashboardProspection {
    width: 80px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputTimeFilterDashboardProspection .fieldInputGeneral {
    padding: 4px 8px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputFilterDashboardProspection .react-date-picker__wrapper {
    border: none!important;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection {
    /* nothing here */
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection {
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .subtitle {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: var(--white-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaSubtitlesChartPieDashboardProspection {
    position: relative;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areSubtitleChartPieDashboardProspection {
    position: relative;
    margin-bottom: 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaSubtitlesChartPieDashboardProspection .areSubtitleChartPieDashboardProspection {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    width: 100%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .cardInfoLeftChartDashboardProspection.exclusive {
    min-height: 419px!important;
    position: relative;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection {
    max-width: 200px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection .avatarDashboardProspection {
    border: 2px solid var(--color-success);
    border-radius: 50%;
    overflow: hidden;
    max-width: 140px;
    height: 100%;
} 

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .itemInfoLeftChartDashboardProspection {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .itemInfoLeftChartDashboardProspection .featured {
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection.italic .strong {
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .lineBoxDashboardProspection {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    display: flex;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection {
    margin: 0;
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.success::before {
    background-color: var(--color-success);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.error::before {
    background-color: var(--color-error);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.focus::before {
    background-color: var(--color-focus);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.information::before {
    background-color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.success {
    color: var(--color-success);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.error {
    color: var(--color-error);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.focus {
    color: var(--color-focus);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.information {
    color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolManager {
    padding-top: 40px;
    overflow: scroll;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
    padding-top: 42px;
    overflow: scroll;
    max-width: initial;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection {
    padding: 10px 10px 5px 10px;
    position: relative;
    border: 2px solid var(--color-success);
    border-radius: 5px;
    border-top-left-radius: 0;
    margin-top: 1px;
    margin-bottom: 50px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection {
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    min-width: 800px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .attendantsLimitedDynamicTableDashboardProspection .lineHeaderLimitedDynamicTable {
    border-top-left-radius: 0px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .performaceIndexDashboardProspection {
    width: initial;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .performaceIndexDashboardProspection .areaPerformaceIndex {
    margin-left: 0;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .flagSupervisor {
    position: absolute;
    background-color: var(--color-focus);
    height: 40px;
    top: -40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    min-width: 350px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .flagManager {
    position: absolute;
    background-color: var(--color-success);
    height: 40px;
    top: -40px;
    left: -2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    min-width: 320px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection {
    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection {
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    width: 7.07%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection.attendant {
    width: 16%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection.left {
    justify-content: flex-start;
    padding-left: 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection:hover .help {
    display: block;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection {
    /* border: 2px solid red; */
}

/* WARNING: content displayed on modal, no in the DashboardProspection */
.lineDetailsCardDashboardProspection {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid var(--white-dark-two);
}

.lineDetailsCardDashboardProspection .iconDetailsCardDashboardProspection {
    cursor: pointer;
    color: var(--color-focus);
}

@media (max-width: 425px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: 66vw;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        margin: 10px;
    }
}

@media (max-width: 768px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: 78vw;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        display: flex;
        flex-direction: column;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection {
        min-width: 200px;
    }

    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .flagManager {
        min-width: 300px;
        padding: 6px
    }

    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.one,
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.three,
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.four {
        /* border: 2px solid green; */
        display: none;
    }
}

@media (min-width: 769px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: initial;
    }

}

@media (max-width: 1439px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection .avatarDashboardProspection {
        max-width: 120px;
        height: 100%;
    }
}

@media (max-width: 1024px)
{
    /* .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        flex-direction: column;
    } */
}