.tableEditAbsenceAttendants {
    background-color: var(--white-light);
    overflow: scroll;
}

.tableEditAbsenceAttendants .helpTableEditAbsenceAttendants {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
}

.tableEditAbsenceAttendants table, tr, th, td {
    margin: 0;
}

.tableEditAbsenceAttendants table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditAbsenceAttendants table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditAbsenceAttendants table tr {
    height: 32px;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus {
    width: 90px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus45 {
    width: 30px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus60 {
    width: 60px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus75 {
    width: 75px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus100 {
    width: 100px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus120 {
    width: 120px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus140 {
    width: 140px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus180 {
    width: 180px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td:last-of-type {
    border-right: none;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants:hover {
    background-color: var(--white-dark);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditAbsenceAttendants td.checkTableEditAbsenceAttendants {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditAbsenceAttendants .table .tbodyTableEditAbsenceAttendants td.checkTableEditAbsenceAttendants {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td:hover .buttonEditTableEditAbsenceAttendants {
    opacity: 1;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td:hover .editTableEditAbsenceAttendants {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td .editTableEditAbsenceAttendants.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td .editTableEditAbsenceAttendants .modalFieldEdit {
    z-index: 1000;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants {
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.noAction {
    cursor: default;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.success { 
    color: var(--color-success);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.error { 
    color: var(--color-error);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.warning { 
    color: var(--color-warning);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants .messageDetailsTableEditAbsenceAttendants {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}


.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.success { 
    color: var(--color-success);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.error { 
    color: var(--color-error);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.warning { 
    color: var(--color-warning);
}