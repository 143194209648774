.service {
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
}

.service .scrollService {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}