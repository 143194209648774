.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button {
    color: black!important;
}

.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(1),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(8),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(15),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(22),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(29),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(36) {
    color: var(--color-error)!important;
    font-weight: 700;
}

.react-date-picker__wrapper {
    /* nothing */
}