.dashboard {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboard .flagTypeServiceDashboard {
    background-color: var(--color-focus);
    height: 40px;
    top: -40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    width: 350px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboard .flagTypeServiceDashboard.newServiceDashboard {
    margin-top: 20px;
}

.dashboard .warningNoContentDashboard {
    background-color: var(--color-warning);
    padding: 10px 14px;
    color: var(--white-light);
    font-size: 14px;
    border-radius: 5px;
}

.dashboard .headerDashboard {
    /* nothing */
}

.dashboard .containerBodyDashboard {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard {
    margin: 20px 20px 0 20px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    transform: rotate(45deg);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard {
    width: 100%;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: inherit;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .inputFilterDashboard {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .buttonSearchFilterDashboard {
    background-color: transparent!important;
    color: var(--color-success);
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard {
    width: 120px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck {
    border-radius: 50%;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck.checked {
    background-color: var(--color-information);
    border: var(--color-information);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck.checked .iconCheck {
    
    color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .table:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard {
    position: relative;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard .areSubtitleChartPieDashboard {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    width: 100%;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard.italic .strong {
    font-weight: 600;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span::before {
    background-color: var(--color-success);
    width: 10px;
    height: 10px;
    border-radius: 2px;
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.success::before {
    background-color: var(--color-success);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.error::before {
    background-color: var(--color-error);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.warning::before {
    background-color: var(--color-warning);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.information::before {
    background-color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .cardInfoLeftChart {
    min-height: 350px!important;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .barsChartCardInfoLeftDashboard {
    /* nothing here */
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .barsChartCardInfoLeftDashboard .columnInfoCardInfoLeftChart {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    vertical-align: middle;
    padding-bottom: 40px;
    padding-left: 10px;
    flex-direction: column;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .boxBodyDashboard {
    width: 100%;
    margin: 0px 20px 20px 20px;
    position: relative;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard {
    color: var(--white-light);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-right: 20px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard.left {
    justify-content: flex-start;
    padding-left: 20px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard:hover .help {
    display: block;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
    font-size: 12px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard.left {
    padding-left: 20px;
    justify-content: flex-start;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard.right {
    justify-content: flex-end;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 48px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard {
    color: var(--white-light);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard.left {
    padding-left: 20px;
    justify-content: flex-start;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard.right {
    padding-right: 20px;
    justify-content: flex-end; 
}

@media (min-width: 768px) and (max-width: 1140px)
{
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard,
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard,
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard
    {
        font-size: 10px;
    }
}

@media (min-width: 1023px)
{
    .dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard {
        position: absolute;
        bottom: 0;
    }
}