.activities {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.activities .warningActivities {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.activities .containerNavigationHeaderActivities {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities { 
    display: flex;
    margin-bottom: 20px;
    position: relative;
    height: 30px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .dropIntervalsHeaderActivities {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 10px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyModalMoreFiltersBusiness {
   padding: 13px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities {
    padding: 5px 13px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colOne {
    padding-right: 2px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colTwo {
    padding-left: 2px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities {
    display: inline-flex;
    flex-direction: row;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button {
    font-size: 14px;
    height: 30px;
    border-radius: 2px;
    margin-right: 2px;
    margin-left: 2px;
    padding: 2px 6px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button.active {
    border: 1px solid var(--color-focus);
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.types {
    justify-content: flex-start;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.intervals {
    justify-content: flex-end;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:hover {
    background-color: var(--white-dark);
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.activities .containerNavigationHeaderActivities .buttonPlusNavigationHeaderActivities {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.activities .containerNavigationHeaderActivities .buttonTrashNavigationHeaderActivities {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: 8px;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities.one {
    margin-left: auto;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .iconFilterHeaderActivities {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities {
    width: 100%;
    border: none;
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .optionsSelectMulti {
    min-width: 200px;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .fieldSelectMulti {
    border: none;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .iconfilterStatusHeaderActivities {
    font-size: 14px;
    color: var(--dark-dark);
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .textShowSelect,
.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .fieldSelect,
.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .select.selectStatusHeaderActivities {
    width: 100%;
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities .selectOwnersHeaderActivities .fieldSelectMulti {
    border: none;
}

.activities .containerListHeaderActivities {
    height: calc(100vh - 56px - 65px - 50px); /*56px of the Header component plus 65px of the .containerNavigationHeaderActivities plus 50px of the .containerTypesAndIntervalsNavigationHeaderActivities*/
}

@media (max-width: 1024px)
{
    .activities .containerNavigationHeaderActivities {
        flex-wrap: wrap;
        height: auto;
        padding: 5px;
        justify-content: center;
    }

    .activities .containerNavigationHeaderActivities .filterHeaderActivities,
    .activities .containerNavigationHeaderActivities .filterHeaderActivities.one {
        margin-left: 0;
    }

    .buttonNavigationHeaderActivities {
        display: none;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities {
        height: auto;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.types,
    .activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.intervals {
        justify-content: center;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .dropIntervalsHeaderActivities {
        width: initial;
        height: 200px;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colOne,
    .activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colTwo {
        padding: 0;
    }

}