.unitCompany {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.unitCompany .containerNavigationHeaderUnitCompany {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:hover {
    background-color: var(--white-dark);
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonPlusNavigationHeaderUnitCompany {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonTrashNavigationHeaderUnitCompany {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}