.campaign {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.campaign .containerNavigationHeaderCampaign {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:hover {
    background-color: var(--white-dark);
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.campaign .containerNavigationHeaderCampaign .buttonPlusNavigationHeaderCampaign {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.campaign .containerNavigationHeaderCampaign .filterPeriodHeaderCampaign {
    padding-left: 0;
    padding-right: 0;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .buttonStatusHeaderCampaign {
    font-size: 16px;
    background-color: transparent;
    padding: 4px 8px;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: var(--primary-color);
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .inputFilterCampaign .fieldInputGeneral {
    border: none!important;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .iconfilterStatusHeaderCampaign {
    font-size: 14px;
    color: var(--dark-dark);
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign {
    cursor: pointer;
    width: 100%;
    border: none;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .textShowSelect,
.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .fieldSelect,
.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .fieldSelectMulti
 {
    border: none;
}

.campaign .containerNavigationHeaderCampaign .dropIntervalsHeaderCampaign {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 0px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;
    overflow: hidden;
}

.campaign .containerNavigationHeaderCampaign .lineBodyDropIntervalsHeaderCampaign {
    padding: 5px 13px;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.campaign .containerListHeaderCampaign {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCampaign*/
    overflow: scroll;
}