@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.messageDocumentWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageDocumentWhatsApp .buttonDefaultMessageDocumentWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageDocumentWhatsApp.right {
    justify-content: flex-end;
}

.messageDocumentWhatsApp.left {
    justify-content: flex-start;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp {
    background-color: #d9fdd3;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp {
    background-color: #fff;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp.noMessage {
    flex-direction: column;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .iconDeletedMessageDocumentWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.initial {
    display: block;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
    cursor: pointer;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textMessageDocumentWhatsApp {
    color: rgb(17, 27, 33);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textMessageDocumentWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textSpaceMessageDocumentWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp {
    padding: 5px 12px 7px 12px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-height: 42px;
    max-height: 82px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.right {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.left {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .shadow {
    opacity: 1;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp {
    width: calc(100% - 58px);
    min-width: 110px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp .iconTypeAnswerMessageDocumentWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .nameAnswerMessageDocumentWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .innerImageAnswerMessageDocumentWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;    
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .innerImageAnswerMessageDocumentWhatsApp .imageInnerImageAnswerMessageDocumentWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp {
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp .iconTypeAnswerMessageDocumentWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .nameAnswerMessageDocumentWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp {
    margin: -3px -4px 6px -6px;
    border-radius: 6px;
    padding: 13px 19px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: flex-start;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.left {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.right {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.noMessage {
    margin-bottom: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .areaIconFileMessageDocumentWhatsApp {
    height: 100%;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .areaIconFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp {
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px!important;
    width: 26px!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.pdf {
    background-image: url("../../assets//images//filePDF.png");
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.general {
    background-image: url("../../assets//images//fileGENERAL.png");
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.doc,
.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.docx {
    background-image: url("../../assets//images//fileWORD.png");
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.xls {
    background-image: url("../../assets//images//fileEXCEL.png");    
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -2px;
    max-width: 90%;
    width: 100%;
    /* white-space: nowrap; */
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .areaNameFileMessageDocumentWhatsApp {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .areaNameFileMessageDocumentWhatsApp .nameFileMessageDocumentWhatsApp {
    color: rgb(17, 27, 33);
    line-height: 19px;
    font-weight: 400;
    word-break: break-all;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp {
    padding-top: 3px;
    padding-bottom: 3px;
    color: #8696a0;
    line-height: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp .infoOthersDetailsFileMessageDocumentWhatsApp {
    color: #8696a0;
    font-size: 11px;
    line-height: 15px;
    display: inline-block;
    text-wrap: nowrap;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp .pointOthersDetailsFileMessageDocumentWhatsApp {
    line-height: 15px;
    color: #8696a0;
    font-weight: 400;
    margin: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 11px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .buttonDownloadFileMessageDocumentWhatsApp .iconButtonDownloadFileMessageDocumentWhatsApp {
    fill: rgba(84,101,111, .5);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp.emphasis .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp {
    display: none!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.left {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.right {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.noAnswer, 
.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.answer {
    background: transparent!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.answer {
    background-color: transparent;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.left.noAnswer {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.right.noAnswer {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.right.noAnswer {
    background: linear-gradient(90deg,rgba(209,244,204,0) 0,rgba(209,244,204,1) 50%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.left.noAnswer {
    background: linear-gradient(90deg,rgba(245,246,246,0) 0,rgba(245,246,246,1) 50%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp.noMessage {
    position: relative;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    /* border: 1px solid green; */
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .timeMessageDocumentWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp {
    margin-left: 3px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.sent {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.delivered {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.read {
    fill: #53bdeb;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.deleted {
    fill: #8696a0;
}