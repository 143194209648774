.containerToastCustom {
    width: 220px;
    position: absolute;
    top: 20px;
    right: 20px;
    overflow: scroll;
    height: 100vh;
}

.containerToastCustom .toastCustom {
    position: relative;
    padding: 5px;
    padding-left: 10px;
    border-radius: 2px;
    color: var(--white-dark);
    font-size: 14px;
    color: var(--dark-dark);
    z-index: 200;
    margin: 5px 0px;
    min-height: 60px;
    background-color: var(--white-dark-three);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}

.containerToastCustom .toastCustom::before {
    display: block;
    content: " ";
    width: 4px;
    height: 10px;
    background-color: var(--color-information);
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.containerToastCustom .toastCustom.success::before {
    background-color: var(--color-success);
}

.containerToastCustom .toastCustom.information::before {
    background-color: var(--color-information);
}

.containerToastCustom .toastCustom.error::before {
    background-color: var(--color-error);
}

.containerToastCustom .toastCustom.warning::before {
    background-color: var(--color-warning);
}