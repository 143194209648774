.cardInfoLeftChart {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
    position: relative;
}

.cardInfoLeftChart .iconCardInfoLeftChart {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4 {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.focus::before {
    background-color: var(--color-focus);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.error::before {
    background-color: var(--color-error);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.warning::before {
    background-color: var(--color-warning);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.information::before {
    background-color: var(--color-information);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.purple::before {
    background-color: var(--color-purple);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft span {
    font-weight: 300;
    color: var(--dark-dark);
    display: inline-block;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnChartCardInfoLeftChart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 5px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnChartCardInfoLeftChart.multipleChildren {
    flex-direction: column;
}