.buttonIcon {
    width: 40px;
    height: 40px;
    outline: none;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: var(--white-light);
    font-size: 18px;
}

.buttonIcon:hover { 
    color: var(--primary-color);
    transition: color .3s;
    background-color: var(--dark-light);
    transition: background-color .3s;
}
