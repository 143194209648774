.tableEditBusiness {
    background-color: var(--white-light);        
    height: 100%;
    overflow: scroll;
}

.tableEditBusiness .helpTableEditBusiness {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditBusiness table, tr, th, td {
    margin: 0;
}

.tableEditBusiness table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditBusiness table .buttonCheckTableEditBusiness {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


.tableEditBusiness table .buttonCheckTableEditBusiness .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditBusiness table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditBusiness table tr {
    height: 32px;
}

.tableEditBusiness .theadTableEditBusiness td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditBusiness .theadTableEditBusiness td:last-of-type {
    border-right: none;
}

.tableEditBusiness td.checkTableEditBusiness {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditBusiness .tbodyTableEditBusiness:hover {
    background-color: var(--white-dark);
}

.tableEditBusiness .tbodyTableEditBusiness:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditBusiness .tbodyTableEditBusiness td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
}

.tableEditBusiness .table .tbodyTableEditBusiness td.checkTableEditBusiness {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .buttonEditTableEditBusiness {
    opacity: 1;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .editTableEditBusiness {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditBusiness .tbodyTableEditBusiness td .editTableEditBusiness.noEdit .buttonFieldEdit {
    display: none;
}