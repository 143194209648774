@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.noOpenedChatWhatsApp {
    height: 100%;
    border-bottom: 6px solid #25d366;
    cursor: default;
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.noOpenedChatWhatsApp .innerNoOpenedChatWhatsApp {
    padding-top: 28px;
    padding-bottom: 28px;
    margin-top: -20px;
    width: 80%;
    max-width: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
    text-align: center;
}

.noOpenedChatWhatsApp .applicationNameNoOpenedChatWhatsApp {
    color: #41525d;
    font-size: 32px;
    font-style: normal;
    line-height: 37.5px;
    margin: 0;
    margin-top: 40px;
    font-family: SF_Pro_Text_Light;
}

.noOpenedChatWhatsApp .descriptionNoOpenedChatWhatsApp {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
    color: #667781;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    bottom: 40px;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp .iconSecureNoOpenedChatWhatsApp {
    fill: rgb(134, 150, 160);
    margin-bottom: 2px;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp .nameSecureNoOpenedChatWhatsApp {
    color: rgb(134, 150, 160);
}