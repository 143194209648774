.groupInputGeneral {  
    width: 100%;
}

.groupInputGeneral .labelInputGeneral {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputGeneral .inputInputGeneral {
    position: relative;
    display: flex;
    align-items: center;
}

.groupInputGeneral .inputInputGeneral .buttonClearInputGeneral {
    background-color: transparent;
    width: inherit;
    height: inherit;
    color: #000;
    font-size: 15px;
    position: absolute;
    right: 10px;
}
.groupInputGeneral .inputInputGeneral .fieldInputGeneral {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral.error { 
    border: 1px solid var(--color-error);
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 6px;
    bottom: 0;
    right: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .buttonInformationInputGeneral { 
    background-color: transparent;
    color: var(--color-warning);
    height: 100%;
    z-index: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral { 
    background-color: var(--white-light);
    min-width: 280px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 34px;
    z-index: 600;
    flex-direction: column;
    display: flex;
    box-shadow: 0px 1px 5px var(--dark-light);
    border: 1px solid #cbcccd;
    border-radius: 2px;
    overflow: scroll;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral { 
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .title { 
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .buttonCloseContentWarningInputGeneal { 
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral {
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 10px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice {
    border-bottom: 1px solid var(--white-dark-two);
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice:hover {
    background-color: var(--white-dark);
    font-weight: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .label {
    font-weight: 300;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .input {
    color: var(--color-focus);
}
