.commissionReportIntervals {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportIntervals .warningCommissionReportIntervals {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:hover {
    background-color: var(--white-dark);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .iconFilterHeaderCommissionReportIntervals {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .fieldSelectMulti {
    border: none;
}

.commissionReportIntervals .containerListHeaderCommissionReportIntervals {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportIntervals */
}