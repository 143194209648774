.tableEditOwners {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditOwners .helpTableEditOwners {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditOwners table, tr, th, td {
    margin: 0;
}

.tableEditOwners table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditOwners table .buttonCheckTableEditOwners {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditOwners table .buttonCheckTableEditOwners .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditOwners table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditOwners table tr {
    height: 32px;
}

.tableEditOwners .theadTableEditOwners td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditOwners .theadTableEditOwners td.minimus {
    width: 90px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus45 {
    width: 45px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus54 {
    width: 54px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus60 {
    width: 60px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus75 {
    width: 75px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus120 {
    width: 120px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus180 {
    width: 180px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus200 {
    width: 200px!important;
}

.tableEditOwners .theadTableEditOwners td:last-of-type {
    border-right: none;
}

.tableEditOwners td.checkTableEditOwners {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditOwners .tbodyTableEditOwners:hover {
    background-color: var(--white-dark);
}

.tableEditOwners .tbodyTableEditOwners:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditOwners .tbodyTableEditOwners td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditOwners .table .tbodyTableEditOwners td.checkTableEditOwners {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditOwners .tbodyTableEditOwners td:hover .buttonEditTableEditOwners {
    opacity: 1;
}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners.minimus500 .modalFieldEdit {
    max-width: 550px!important;
}

.tableEditOwners .tbodyTableEditOwners td:hover .editTableEditOwners {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditOwners .tbodyTableEditOwners td .stampEditOwners {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners .modalFieldEdit {
    z-index: 1000;
}

.tableEditOwners .tbodyTableEditOwners td .buttonShowConfigTableEditOwners {
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
}

.tableEditOwners .tbodyTableEditOwners td .buttonShowConfigTableEditOwners.error {
    color: var(--color-error);
}

.tableEditOwners .tbodyTableEditOwners td .companyEditOwners {
    position: absolute;
    z-index: 99;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    border-radius: 2px;
    margin-right: 8px;
    text-align: center;
}