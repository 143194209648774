.audioPlayer {
    height: 50px;
    min-width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.audioPlayer .rangeAudioPlayer {
    width: 100%;
}

.audioPlayer .audioAudioPlayer {
    position: absolute;
    left: 0;
    top: 0;
}

/* https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/ */
.audioPlayer input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    background-color: #e7e8e9;
    height: 3px;
    transition: background .5s;
    z-index: 200;
}

.audioPlayer .progressAudioPlayer {
    width: 0%;
    height: 5px;
    background-color: #30b0e8;
    content: " ";
    left: 0;
    position: absolute;
    margin-top: -1px;
    z-index: 100;
}

/***** Start Track Styles *****/
/***** Start Track Styles *****/
/***** Start Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.audioPlayer input[type="range"]::-webkit-slider-runnable-track {
    /* DANGER: Don't uncomment and remove. */
    /* background: linear-gradient(to right, #30b0e8 5%, grey 5%); WARNING: setting the progress here, on zero position */
    width: 100%;
    height: 3px;
}

.audioPlayer input[type="range"].safari::-webkit-slider-runnable-track {
    width: 50%!important;
    height: 5px;
    margin-top: -3px;
    background-color: none;
    box-shadow: none;
    border: none;
    outline: none;
}

/******** Firefox ********/
.audioPlayer input[type="range"]::-moz-range-track {
    /* DANGER: Don't uncomment and remove. */
    /* background: linear-gradient(to right, #30b0e8 5%, grey 5%); WARNING: setting the progress here, on zero position */
    width: 20%;
    height: 3px;
}
/***** Ends Track Styles *****/
/***** Ends Track Styles *****/
/***** Ends Track Styles *****/

/***** Start Thumb Styles *****/
/***** Start Thumb Styles *****/
/***** Start Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.audioPlayer input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #30b0e8;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin-top: -5px;
    z-index: 1;
}

.audioPlayer input[type="range"].noListened::-webkit-slider-thumb {
    background-color: #6f8171;
}

.audioPlayer input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #30b0e8;
    height: 13px;
    width: 13px;
    border-radius: 50%;
}

.audioPlayer input[type="range"].noListened::-moz-range-thumb {
    background-color: #6f8171;
}
/***** Ends Thumb Styles *****/
/***** Ends Thumb Styles *****/
/***** Ends Thumb Styles *****/

@media (max-width: 1023px)
{
    .audioPlayer {
        min-width: 100px;
    }
}