.advancedBusiness {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.advancedBusiness .warningAdvancedBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.advancedBusiness .containerHeaderAdvancedBusiness {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;
    min-width: 47px;
    padding: 4px;
    vertical-align: top;
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness:hover {
    background-color: var(--white-dark);
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness:first-of-type {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.advancedBusiness .containerListAdvancedBusiness {
    height: calc(100vh - 56px - 65px);
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonHeaderAdvancedBusiness {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.advancedBusiness .buttonApplyFiltersAdvancedBusiness {
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.advancedBusiness .advancedBusinessLoading {
    transition: all ease-in .5s;
    position: absolute;
    bottom: 10px;
    right: 10px;
}