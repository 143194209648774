.commissionReportSupervisor {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportSupervisor .warningCommissionReportSupervisor {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:hover {
    background-color: var(--white-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonPlusNavigationHeaderCommissionReportSupervisor {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonTrashNavigationHeaderCommissionReportSupervisor {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .iconFilterHeaderCommissionReportSupervisor {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .fieldSelectMulti {
    border: none;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .iconfilterStatusHeaderCommissionReportSupervisor {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .textShowSelect,
.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .fieldSelect,
.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .select.selectStatusHeaderCommissionReportSupervisor {
    width: 100%;
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterOwnersHeaderCommissionReportSupervisor {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterOwnersHeaderCommissionReportSupervisor .selectOwnersHeaderCommissionReportSupervisor .fieldSelectMulti {
    border: none;
}

.commissionReportSupervisor .containerListHeaderCommissionReportSupervisor {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportSupervisor */
}