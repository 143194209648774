.connectionLogs {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.connectionLogs .warningConnectionLogs {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.connectionLogs .scrollConnectionLogs {
    overflow: scroll;
    height: 100%;
}

.connectionLogs .headerConnectionLogs h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.connectionLogs .headerConnectionLogs h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);

}

.connectionLogs .bodyConnectionLogs  {
    margin-bottom: 250px;
    margin-top: 20px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
    display: flex;
    justify-content: flex-end;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin: 5px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs.extra {
    min-width: 380px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .buttonToggleIntervalsConnectionLogs {
    background-color: transparent!important;
    color: var(--primary-color)!important;
    font-size: 14px;
    padding: 4px 8px;
    height: 32px;
    font-weight: 400;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .iconFilterBodyConnectionLogs {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs {
    width: 100%;
    border: none;
    cursor: pointer;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .optionsSelect,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .optionsSelect {
    min-width: 200px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .textShowSelect,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .fieldSelectMulti,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .fieldSelect{
    border: none;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .dropIntervalsBodyConnectionLogs {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 10px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineBodyDropIntervalsBodyConnectionLogs {
    padding: 5px 13px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs {
    padding-bottom: 20px;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs .columnChosenConnectionLogsGroupConnectionLogs { 
    width: 100%;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs .columnChosenConnectionLogsGroupConnectionLogs .buttonPlusConnectionLogsGroupPermisions {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.connectionLogs .bodyConnectionLogs .lineTitleHeaderConfigConnectionLogs {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.connectionLogs .bodyConnectionLogs .lineTitleHeaderConfigConnectionLogs .columnTitleHeaderConfigConnectionLogs {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs.pair {
    background-color: var(--white-dark-three);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs.left {
    display: flex;
    justify-content: flex-start;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonBodyConfiConnectionLogs {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonBodyConfiConnectionLogs:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs {
    width: 30px;
    height: 30px;
    background-color: black;
    content: " ";
    display: block;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs.success {
    background-color: var(--color-success);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs.information {
    background-color: var(--color-information);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonInsertWhiteListConnectionLogs {
    background-color: transparent;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonInsertWhiteListConnectionLogs .iconButtonIcon {
    color: var(--color-information);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog {
    font-size: 18px;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.success {
    color: var(--color-success);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.error {
    color: var(--color-error);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.warning {
    color: var(--color-warning);
}

@media (max-width: 1366px){
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
        justify-content: center;
    }
    
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .dropIntervalsBodyConnectionLogs {
        left: -115px;
        right: initial;
    }
}