.tableEditCommissionRulesIntervals {
    background-color: var(--white-light);
}

.tableEditCommissionRulesIntervals .helpTableEditCommissionRulesIntervals {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRulesIntervals table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRulesIntervals table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRulesIntervals table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRulesIntervals table tr {
    height: 32px;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td:last-of-type {
    border-right: none;
}

.tableEditCommissionRulesIntervals td.checkTableEditCommissionRulesIntervals {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRulesIntervals .table .tbodyTableEditCommissionRulesIntervals td.checkTableEditCommissionRulesIntervals {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td:hover .buttonEditTableEditCommissionRulesIntervals {
    opacity: 1;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td:hover .editTableEditCommissionRulesIntervals {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .editTableEditCommissionRulesIntervals.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .editTableEditCommissionRulesIntervals .modalFieldEdit {
    z-index: 1000;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.success { 
    color: var(--color-success);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.error { 
    color: var(--color-error);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.warning { 
    color: var(--color-warning);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals .messageDetailsTableEditCommissionRulesIntervals {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td.minimus {
    width: 90px!important;
}