.training {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.training .scrollTraining {
    overflow: scroll;
    height: 100%;
}

.training .headerTraining {
    margin-top: 20px;
}

.training .headerTraining h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.training .headerTraining h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);
}

.training .bodyTraining .materialTraining {
    border-bottom: 1px solid var(--dark-light);
    padding: 10px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.training .bodyTraining .materialTraining:last-of-type {
    border-bottom: none;
}

.training .bodyTraining .materialTraining .iconTraining {
    font-size: 18px;
    color: var(--color-information);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 5px;
}

.training .bodyTraining .materialTraining .nameTraining {
    color: var(--color-information);
    font-size: 18px;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}

.training .bodyTraining .materialTraining .nameTraining:hover {
    color: var(--color-success);
    transition: 2s color;
}

.training .bodyTraining .materialTraining .dateTraining {
    right: 30px;
    color: var(--dark-dark-three);
    position: absolute;
    font-weight: 300;
}