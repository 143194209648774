.selectMulti { 
    position: relative;
    min-width: 100px;
}

.selectMulti .labelSelectMulti { 
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.selectMulti .fieldSelectMulti .lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.selectMulti .fieldSelectMulti .lead.error {
    background-color: var(--color-error);
}

.selectMulti .fieldSelectMulti .lead.warning {
    background-color: var(--color-warning);
    color: #000;
}

.selectMulti .fieldSelectMulti .lead.focus {
    background-color: var(--color-focus);
}

.selectMulti .fieldSelectMulti {
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    padding: 4px 8px;
    height: 32px;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.selectMulti .fieldSelectMulti div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectMulti .fieldSelectMulti .iconShowSelectMulti{
    position: absolute;
    font-size: 14px;
    right: 10px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.selectMulti .optionsSelectMulti {
    z-index: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%), 0 3px 4px 0 rgb(0 0 0 / 5%), 0 3px 3px -2px rgb(0 0 0 / 6%), 0 1px 8px 0 rgb(0 0 0 / 10%);

}

.selectMulti .optionsSelectMulti .active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.selectMulti .optionsSelectMulti .iconLabelSelectedOptionSelect {
    color: var(--white-light);    
}