.performanceIndex {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.performanceIndex .namePerformaceIndex {
    color: var(--primary-color)!important;
    font-weight: 600!important;
}

.performanceIndex .areaPerformaceIndex {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    font-size: 22px;
    margin-left: 8px;
}

.performanceIndex .percentPerformaceIndex {
    color: var(--primary-color)!important;
    font-weight: 400!important;
}

.performanceIndex .percentPerformaceIndex.positive {
    color: var(--color-success)!important;
}

.performanceIndex .percentPerformaceIndex.negative {
    color: var(--color-error)!important;
}

.performanceIndex .percentPerformaceIndex.equal {
    color: var(--color-information)!important;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex {
    color: black;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.positive {
    color: var(--color-success);
    margin-bottom: -14px;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.negative {
    color: var(--color-error);
    margin-top: -14px;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.equal {
    color: var(--color-information);
    font-size: 12px;
}