.personOnline {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.personOnline .amountPersonOnline {
    width: 170px!important;
    font-size: 16px;
    color: var(--dark-dark);
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.personOnline .amountPersonOnline:before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-success);
    margin-right: 10px;
}

.personOnline .scrollPersonOnline {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.personOnline .headerPersonOnline {
    margin-top: 20px;
}

.personOnline .headerPersonOnline h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.personOnline .headerPersonOnline h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-focus);
    transform: rotate(45deg);
}

.personOnline .bodyPersonOnline {
    margin-bottom: 300px;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid transparent;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header .minimum {
    font-weight: 600;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header .minimum.right {
    justify-content: flex-end;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum {
    width: 150px;
    font-size: 16px;
    color: var(--dark-dark);
    display: flex;
    align-items: center;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.body .minimum.right {
    justify-content: flex-end;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.body .minimum:first-child::before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-success);
    margin-right: 10px;
}

@media (max-width: 767px) {
    .personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum.name {
        margin: 0 10px;
        max-width: 100px;
    }

    .personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum {
        width: initial;
    }
}