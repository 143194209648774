.containerWizard {
	/* max-width: 600px; */
	margin: 0 auto;
	/* border: 2px solid red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.containerWizard .titleWizard {
	color: #000;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #a8a8a8;
    padding: 12.5px 12.5px;
    margin: 15px 0;

}

.containerWizard .progressBarWizard {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 95%;
}

.containerWizard .progressStepWizard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e0e0e0;
    font-size: 14px;
    color: #fff;
}

.containerWizard .progressStepTitleWizard {
    margin-top: 10px;
}

.containerWizard .progressStepWizard .visited{
    background-color: var(--color-focus);
}

.visited .progressStepWizard {
    background-color: var(--color-focus);
}

.containerWizard .containerCurrentPageWizard {
    width: 100%;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard {
    background-color: transparent;
    color: var(--dark-dark-three);    
    margin-right: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

/* .containerWizard .containerCurrentPageWizard .alertHasAnotherServiceWithEnvelope {
    width: 40.5%;
} */

.containerWizard .containerCurrentPageWizard .attachServicesButton {
    background: var(--color-focus);
	/* width: 180px; */
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard::after {
    content: "Arquivo";
    display: block;
    position: absolute;
    right: -70px;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-light);
    border-radius: 50%;
}


.containerWizard .containerCurrentPageWizard .nameFileWizard {
    color: var(--dark-dark-three);    
    font-weight: 300;
}

.containerWizard .mandatoryInformationWizard{
    font-size: 12px;
    color: #747678;
    width: 100%;
    margin-bottom: 5px;
}

.containerWizard .boxStepWizard {
    display: flex;
    width: 165px;
    flex-direction: column;
    align-items: center;
}

.containerWizard .progressStepWizard.active {
    background-color: #4caf50;
}

.containerWizard .progressConnectorWizard {
    flex: 1;
    height: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
}

.containerWizard .progressConnectorWizard.active {
    background-color: #4caf50;
}

.containerWizard .stepWizard {
  	margin: 20px 0;
}

.containerWizard .navigationWizard {
    border-top: 1px solid #e5e5e5;
    margin-top: 1em;
    padding-top: 1em;
  	display: flex;
  	justify-content: space-between;
}

.containerWizard .navigationWizard .buttonWizard {
	background: var(--color-success);
	width: 180px;
	/* height: 46px; */
	font-size: 12px;
}

.containerWizard .navigationWizard .buttonWizard {
	background: var(--color-focus);
	width: 180px;
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .navigationWizard .buttonDisabledWizard {
	background: var(--dark-light);
}

.containerWizard .containerClientWizard .clientRegisteredWizard {
    background: #b2ddc2;
    width: 100%;
    padding: 1em;
    border-radius: 5px;
    color: #000;
    display: block;
}

.containerWizard .containerClientWizard .boxCheckDataClientServiceFormWizard {
    width: 100%;
    box-sizing: border-box;
    font: inherit;
    display: flex;
    height: 46px;
    margin-top: 1px;
    color: #26292c;
    border: solid 1.5px #9e9e9e;
    border-radius: 5px;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    align-items: center;
}

.containerWizard .modernFieldInputBondsWizard .fieldInputGeneral {
    box-shadow: none !important;
    width: 100% !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .modernFieldInputGeneral .fieldInputGeneral {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .inputGroupComponent .inputInputGroup .buttonInputGroup {
    display: flex !important;
    height: 46px !important;
    max-height: 46px !important;
    margin-left: -1px;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: solid 1.5px #9e9e9e !important;
}

.containerWizard .inputGroupComponent .inputInputGroup .form-control {
    width: 100%;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1.5px #9e9e9e !important;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 1rem;
    height: 46px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
}

.containerWizard .modernSelectFieldGeneral .fieldSelect {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}


.containerWizard .containerInstallmentsWizard .installmentDetailsWizard {
    box-shadow: #b7d5b212 0px 1px 1px 1px;
    border: solid 1px #b7d5b2;
    border-radius: 5px;
    padding: 0.75em;
    user-select: none;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .statusShipmentWizard{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard img.statusShipmentWizard{
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerImageStatusShipimentWizard{
    width: 500px;
}

.containerWizard .buttonAddContactWizard {
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    width: fit-content;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerSaveClientWizard {
    display: flex;
    justify-content: flex-end;
}

.containerWizard .buttonSaveClientWizard {
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    width: fit-content;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerWitnessesWizard .buttonBondsWizard {
    margin-right: 6px;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .witnessTableWizard {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.containerWizard .containerWitnessesWizard .witnessTableHeaderWizard {
    background-color: #f0f0f0;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #ddd;
}

.containerWizard .containerWitnessesWizard .witnessWizard {
    background: #f9f9f9;
    border: 2px solid #e0e0e0;
    margin: 0 !important;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.containerWizard .containerWitnessesWizard .witnessWizard:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableNameWizard {
    flex: 2;
    text-align: left;
    font-weight: bold;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard {
    display: flex;
    justify-content: space-between;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard .witnessTableStatusWizard {
    font-weight: bold;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard .witnessTableTimestampWizard {
    font-size: 0.9em;
    color: #555;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard {
    border-radius: 5px;
    height: fit-content;
    max-width: fit-content;
    min-width: 553px;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  p, .containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  b, .containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  span{
    font-size: 16.5px !important;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  .labelsEnvelopeWizard {
    font-weight: 300;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  .labelsDateEnvelopeWizard {
    font-size: 14px !important;
    font-weight: 400;
    font-style: italic;
}

.containerWizard .containerWitnessesWizard .buttonEnvelopeWizard {
    margin-right: 6px;
    height: 46px;
    font-weight: 400;
    font-size: 15px;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .buttonSendAttachmentEnvelopeWizard {
    width: 100%;
    background-color: var(--color-focus);
}

.containerWizard .containerWitnessesWizard .buttonRemoveWitness {
    background: var(--color-error);
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerWizard .containerWitnessesWizard .buttonValidationWitnessWizard {
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard {
    border: 1px solid #ccc;
    padding: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard .titleDetailsEnvelopeWizard {
    /* background: #ccc; */
    padding: 4px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard .buttonUrlLinkSignaturesWizard {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 4em;
    border: 0;
    text-align: center;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard table td, .containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard table th {
    text-align: -webkit-center;
    vertical-align: middle;
    font-weight: 400;
}

.containerWizard .containerWitnessesWizard .buttonRoundIconEnvelopeWizard {
    width: 2em;
    border-radius: 25px;
    text-align: center;
    background: #464646;
}

.containerWizard .containerWitnessesWizard .buttonTimerEnvelopeWizard {
    width: 90%;
    border-radius: 25px;
    text-align: center;
    background: #5328a7;
}
.containerWizard .containerWitnessesWizard .spanInfoSignedWizard {
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: 120px;
    background: #ccc;
    font-weight: 400;
    color: #fff;
}

.containerWizard .containerWitnessesWizard .buttonTimerEnvelopeWizard i{
    margin-right: 5px;
}

.containerWizard .containerWitnessesWizard .timerEnvelopeWizard{
    border-radius: 5px;
    margin-right: 6px;
    width: 100px;
    color: #fff;
    background: #3b2070;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerWizard .containerWitnessesWizard .timerEnvelopeWizard i{
    margin-right: 5px;
}
.containerWizard .containerWitnessesWizard .timerEnvelopeWizard span{
    height: 21px;
    font-weight: 400;
}

.containerWizard .containerWitnessesWizard .buttonDeleteEnvelopeWizard {
    background: var(--color-error);
    width: 50px !important;
}

.containerWizard .containerWitnessesWizard .buttonErrorAlertEnvelopeWizard {
    background: var(--color-error) !important;
    min-width: fit-content;
}
.containerWizard .containerWitnessesWizard .buttonStatusSignatoriesWizard {
    background: var(--color-warning);
}
.containerWizard .containerWitnessesWizard .buttonDetailsEnvelopeWizard {
    background: var(--color-focus);
}
.containerWizard .containerWitnessesWizard .buttonCreateEnvelopeWizard, .containerWizard .containerWitnessesWizard .buttonSendSignWizard {
    background: var(--color-success);
}

.containerWizard .containerWitnessesWizard .colorTextErrorDisableEnvelope {
    color: var(--color-error);
}

.containerWizard .containerWitnessesWizard .accordion-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid #c7c7c7 !important;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: 1rem 1.25rem;
    position: relative;
    text-align: left;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    width: 100%;
}

.containerWizard .containerWitnessesWizard .accordion-button:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    width: 16px;
    height: 16px;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.containerWizard .containerWitnessesWizard .accordion-button:not(.collapsed):after {
    transform: rotate(180deg);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSign {
    font-size: 16px;
    color: #fff;
    width: 100px;
    display: flex;
    font-weight: 400;
    width: 100%;
    height: 46px;
    align-items: center;
    justify-content: center;
}

.containerWizard .containerWitnessesWizard .buttonOnDetaisEnvelopeTitle {
    width: 160px;
}

.containerWizard .containerWitnessesWizard .buttonOnDetaisEnvelope {
    width: 160px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .buttonOnDetailsEnvelopeSignatures {
    background-color: var(--color-warning);
}

.containerWizard .containerWitnessesWizard .buttonOnDetailsEnvelopeAttachments {
    background-color: var(--color-inactive);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSignNot {
    background-color: var(--color-warning);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSignYes {
    background-color: var(--color-success);
}

.containerWizard .containerDetailsFinanceWizard .buttonDebtsWizard {
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerDetailsFinanceWizard .buttonCancelDebtsWizard {
    background: var(--color-warning);
}

.containerWizard .containerDetailsFinanceWizard .buttonSaveDebtsWizard {
    background: var(--color-success);
}

.containerWizard .containerDetailsFinanceWizard .titleInstallmentsWizard {
    font-size: 20px;
    font-weight: 400;
    color: #4caf50;
}
.containerWizard .containerDetailsFinanceWizard .buttonPrintCarne {
	background: var(--color-focus);
	/* width: 180px; */
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .modernSelectFieldGeneral .fieldSelect {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .modernSelectFieldGeneral .fieldSelectMulti {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}