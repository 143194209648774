.messageAttention {
    background-color: rgba(0, 0, 0, 0.342);
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
}

.messageAttention.animate .content {
    animation: shake 0.5s ease-in-out;
    /* box-shadow: 0px 0px 8px 5px #ffc107;
    border: 2px solid #ffc107; */
}

.messageAttention .buttonMessageAttention {
    width: 100px;
    font-weight: 400;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    margin: 0;
}

.messageAttention .sendMessageAttention{
    max-height: 32px;
    width: fit-content;
    background-color: var(--color-warning);
}

.messageAttention .includeMessageAttention{
    min-height: 60px;
    width: 200px;
    font-size: 20px;
    margin-bottom: 15px;
    background-color: var(--color-success);
}

.messageAttention .onConfirm {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-success);
}


.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageAttention .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageAttention .content .header {
    border-bottom: 0.5px solid #e0e0e0;
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 24px;
}

.messageAttention .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageAttention .content .header .status.success{ color: #28a745 }

.messageAttention .content .header .status.error{ color: #dc3545 }

.messageAttention .content .header .status.warning{ color: #ffc107 }

.messageAttention .content .header .status.information{ color: #007bff }

.messageAttention .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageAttention .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageAttention .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */


/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageAttention .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 24px 0;
}

.messageAttention .content .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 14px 24px;
}

.messageAttention .content .body p {
   font-size: 16px;
   font-weight: 400;
   color: var(--dark-dark);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */