.lineCheckRequirement {
    display: flex;
    flex-direction: row;
    min-width: 250px;
    padding: 8px 12px;
    margin: 5px;
}

.lineCheckRequirement.success {

}

.lineCheckRequirement .columnIconCheckRequirement {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement {
    font-size: 14px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid var(--dark-light);
    color: var(--dark-light);
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement.success {
    color: var(--color-success)!important;
    border: 1px solid var(--color-success);
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement.error {
    color: var(--color-error)!important;
    border: 1px solid var(--color-error);
}

.lineCheckRequirement .columnIconCheckRequirement i.iconCheckRequirement {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    font-size: 10px;
    padding: 0;
    margin: 0;
}

.lineCheckRequirement .columnLabelCheckRequirement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    color: var(--dark-light);
    padding-left: 10px;
    font-size: 14px;
}

.lineCheckRequirement .columnLabelCheckRequirement span.error {
    color: var(--color-error)!important;
}

.lineCheckRequirement .columnLabelCheckRequirement span.success {
    color: var(--color-success)!important;
}