.tableEditCommissionRulesProspection {
    background-color: var(--white-light);
}

.tableEditCommissionRulesProspection .helpTableEditCommissionRulesProspection {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRulesProspection table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRulesProspection table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRulesProspection table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRulesProspection table tr {
    height: 32px;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td:last-of-type {
    border-right: none;
}

.tableEditCommissionRulesProspection td.checkTableEditCommissionRulesProspection {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRulesProspection .table .tbodyTableEditCommissionRulesProspection td.checkTableEditCommissionRulesProspection {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td:hover .buttonEditTableEditCommissionRulesProspection {
    opacity: 1;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td:hover .editTableEditCommissionRulesProspection {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .editTableEditCommissionRulesProspection.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .editTableEditCommissionRulesProspection .modalFieldEdit {
    z-index: 1000;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.success { 
    color: var(--color-success);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.error { 
    color: var(--color-error);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.warning { 
    color: var(--color-warning);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection .messageDetailsTableEditCommissionRulesProspection {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td.minimus {
    width: 90px!important;
}