.subtitleProgressLine {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-bottom: 2px;
}

.subtitleProgressLine .labelSubtitleProgressLine {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    margin-bottom: -12px;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .barSubtitleProgressLine {
    width: 100%;
    height: 5px;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine {
    color: #000;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.divider {
    cursor: default;
    margin: 0;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.two {
    margin: 0;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.withAction {
    cursor: pointer;
}