.menusCRM {
    padding: 0;
    margin: 0;
    background-color: #404346;
    width: 56px;
    height: 100%;
}

.menusCRM .anchorMenu {
    text-decoration: none;    
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
}

.menusCRM .anchorMenu .amountNotificationsMenusCRM {
    position: absolute;
    right: 0;
    background-color: var(--color-error);
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: var(--white-dark);
    border-radius: 50%;
    font-size: 14px;
}

.menusCRM .anchorMenu .squareMenu {
    width: 56px;
    height: 56px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    color: var(--white-dark-two);
    font-size: 24px;
}

.menusCRM .anchorMenu .squareMenu.brandMenu {
    fill: red;
    color: red;
}

.menusCRM .anchorMenu .squareMenu:hover {
    background-color: rgba(255, 255, 255, 0.16);
    transition: background-color 0.2s;
}

.menusCRM .anchorMenu .squareMenu.active {
    background-color: var(--dark-dark-two);
}

.menusCRM .anchorMenu .flagMenu.active {
    content: "";
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0px;
}

.menusCRM .anchorMenu .moreInformationMenu {
    position: absolute;
    padding: 1px 8px;
    background-color: var(--dark-dark-two);
    color: white;
    font-size: 14px;
    position: absolute;
    border-radius: 4px;
    margin-left: 58px;
    align-items: center;
    vertical-align: middle;
    display: none;
    z-index: 900;
    width: max-content;
}

.menusCRM .anchorMenu:hover .moreInformationMenu {
    display: flex;
}

.menusCRM .anchorMenu .moreInformationMenu .numberInformationMenu{
    margin-left: 8px;
    display: inline-flex;
    padding: 0px 4px;
    height: 16px;
    min-width: 16px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffffa3;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}

.menusCRM .dividerMenu {
    margin: 8px 12px;
    border: 1px solid rgba(38, 41, 44, 0.64);
    border-radius: 1px;
}

.menusCRM .btnLogout {
    position: absolute;
    bottom: 0;
    background-color: #404346;
}