.personalPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.personalPreferences .scrollPersonalPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.personalPreferences .headerPersonalPreferences {
    margin-top: 20px;
}

.personalPreferences .headerPersonalPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.personalPreferences .headerPersonalPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-focus);
    transform: rotate(45deg);
}

.personalPreferences .bodyPersonalPreferences {
    margin-bottom: 300px;
}

.personalPreferences .bodyPersonalPreferences .chosenAvatarPersonPreferences .inputInputGeneral input {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    padding: 0px;    
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences .columnTitleHeaderConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    width: 100px!important;

}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}