.commissionReportAttendant {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportAttendant .warningCommissionReportAttendant {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:hover {
    background-color: var(--white-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonPlusNavigationHeaderCommissionReportAttendant {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonTrashNavigationHeaderCommissionReportAttendant {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .iconFilterHeaderCommissionReportAttendant {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .fieldSelectMulti {
    border: none;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .iconfilterStatusHeaderCommissionReportAttendant {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .textShowSelect,
.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .fieldSelect,
.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .select.selectStatusHeaderCommissionReportAttendant {
    width: 100%;
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterOwnersHeaderCommissionReportAttendant {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterOwnersHeaderCommissionReportAttendant .selectOwnersHeaderCommissionReportAttendant .fieldSelectMulti {
    border: none;
}

.commissionReportAttendant .containerListHeaderCommissionReportAttendant {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportAttendant */
}