.dropDown { 
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    min-width: 100px;
    min-height: 120px;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}