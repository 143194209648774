.dashboardMarketing {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboardMarketing .headerDashboardMarketing {
    /* nothing */
}

.dashboardMarketing .containerBodyDashboardMarketing {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing {
    z-index: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    z-index: inherit;
    margin: 20px 20px 0 20px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .iconFilterCompanyUnitDashboardMarketing {
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
    cursor: pointer;
    width: 100%;
    border: none;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .fieldSelectMulti {
    border: none;
    box-shadow: none;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .optionsSelectMulti {
    z-index: 200;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .checkSpecificPeriodDashboardMarketing {
    max-width: 220px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .buttonTodayDashboardMarketing {
    width: 50px;
    height: 30px;
    color: var(--color-success);
    background-color: transparent!important;
    border: 1px solid var(--color-success);
    font-size: 10px;
    margin: 0 5px;
    border-radius: 2px;
    padding: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .buttonSearchReportDashboardMarketing {
    background-color: transparent!important;
    color: var(--color-success);
    height: inherit;
    margin-left: 10px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputFilterDashboardMarketing {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputTimeFilterDashboardMarketing {
    width: 80px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputTimeFilterDashboardMarketing .fieldInputGeneral {
    padding: 4px 8px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputFilterDashboardMarketing .react-date-picker__wrapper {
    border: none!important;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing {
    margin: 20px 20px 0 20px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    transform: rotate(45deg);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing {
    width: 100%;
    z-index: 0;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing.filterDashboardMarketing {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing.filterDashboardMarketing .inputFilterDashboardMarketing {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing {
    /* nothing here */
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing {
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .cardInfoLeftChartDashboardMarketing.exclusive .columnChartCardInfoLeftChart {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    align-items: flex-start;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .cardInfoLeftChartDashboardMarketing {
    min-height: 296px!important;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing {
    max-width: 200px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing .avatarDashboardMarketing {
    border: 2px solid var(--color-success);
    border-radius: 50%;
    overflow: hidden;
    max-width: 140px;
    height: 140px;
} 

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .itemInfoLeftChartDashboardMarketing {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .itemInfoLeftChartDashboardMarketing .featured {
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing.italic .strong {
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .lineBoxDashboardMarketing {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    display: flex;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.success::before {
    background-color: var(--color-success);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.error::before {
    background-color: var(--color-error);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.focus::before {
    background-color: var(--color-focus);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.information::before {
    background-color: var(--color-information);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.success {
    color: var(--color-success);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.error {
    color: var(--color-error);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.focus {
    color: var(--color-focus);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.information {
    color: var(--color-information);
}

/* WARNING: content displayed on modal, no in the DashboardMarketing */
.lineDetailsCardDashboardMarketing {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid var(--white-dark-two);
}

.lineDetailsCardDashboardMarketing .iconDetailsCardDashboardMarketing {
    cursor: pointer;
    color: var(--color-focus);
}

@media (max-width: 425px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: 66vw;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
        min-width: 120px;
        max-width: 120px;
    }

}

@media (max-width: 768px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: 78vw;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing {
        display: flex;
        flex-direction: column;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
        min-width: 200px;
    }

}

@media (min-width: 769px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: initial;
    }

}

@media (max-width: 1439px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing .avatarDashboardMarketing {
        max-width: 120px;
        height: 120px;
    }
}