#info-tooltip .tooltip-inner {
    text-align: left;
    background: var(--color-warning);
    color: #fff;
}
#info-tooltip .tooltip-inner i {
    margin-bottom: 1px;
}
.tooltip .tooltip-arrow {
    content: '';
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent var(--color-warning) transparent;
}

.large-modal {
    background-color: rgba(0, 0, 0, 0.342);
}
modal-
.content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.modal-fullscreen { 
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
}

.modal-fullscreen .modal-content { 
    border: 0;
    border-radius: 0 !important;
    height: 100%;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
}

.modal-fullscreen .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
}

.modal-fullscreen .footer, .modal-fullscreen .header {
    border-radius: 0;
}

.modal-fullscreen .body {
    overflow-y: auto;
}

.large-modal .modal-content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* .modal-fullscreen .modal-content .modal-content { 
    border-radius: 0;
} */

/* Start styling header */
/* Start styling header */
/* Start styling header */
.large-modal .modal-content .header {
    border-bottom: 0.5px solid #e0e0e0;
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.large-modal .modal-content .header .status {
    font-weight: 600;
    font-size: 16px;
}

.large-modal .modal-content .header .titleService, .large-modal .modal-content .header .title {
    font-size: 20px;
    font-weight: 300;
}

.large-modal .modal-content .header .containerInfoAndCloser {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
}

.large-modal .modal-content .header .containerInfoAndCloser .informationLargeModal {
    border: 1px solid var(--color-information);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent !important;
}

.large-modal .modal-content .header .informationLargeModal .iconInfo {
    font-size: 14px;
    color: var(--color-information);
}

.large-modal .modal-content .header .status.success{ color: #28a745 }

.large-modal .modal-content .header .status.error{ color: #dc3545 }

.large-modal .modal-content .header .status.warning{ color: #ffc107 }

.large-modal .modal-content .header .status.information{ color: #007bff }

.large-modal .modal-content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.large-modal .modal-content .header .containerInfoAndCloser .close{
    margin-left: 10px;
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.large-modal .modal-content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.large-modal .modal-content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.large-modal .modal-content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.large-modal .modal-content .body .lineBodyLargeModal {
    width: 100%;
}

.large-modal .modal-content .body .lineBodyLargeModal .columnBodyMessageConfirmation .buttonMessageConfirmation {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.large-modal .modal-content .body .lineBodyLargeModal .columnBodyMessageConfirmation .buttonMessageConfirmation.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */