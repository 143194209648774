.integrations {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.integrations .warningIntegrations {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.integrations .scrollIntegrations {
    overflow: scroll;
    height: 100%;
}

.integrations .headerIntegrations {
    margin-top: 20px;
}

.integrations .headerIntegrations h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.integrations .headerIntegrations h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-error);
    transform: rotate(45deg);

}

.integrations .bodyIntegrations  {
    /* nothing */
}