.development {
    height: 100vh;
    position: relative;
    overflow: scroll;

    --dark-version-one: purple;
    --dark-version-two: rgb(149, 0, 149);
    --dark-version-three: rgb(173, 70, 173);

    --light-version-one: var(--dark-dark-three);
    --light-version-two: var(--white-light);
    --light-version-three: var(--white-dark-two);
}

.development .warningDevelopment {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.development .scrollDevelopment {
    overflow: scroll;
    height: 3000px;
}

.development .scrollDevelopment .scrollDemoDevelopment {
    height: 200px;
    width: 200px;
    overflow: scroll;
    background-color: #f0db4f
}

.development .scrollDevelopment .scrollDemoDevelopment p {
    height: 300px;
    width: 300px;
}

.development .headerDevelopment {
    margin-top: 20px;
}

.development .headerDevelopment h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.development .headerDevelopment h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: purple;
    transform: rotate(45deg);

}

.development .heart {
    color: red;
}

/* start overwritten DatePickerDark */
/* start overwritten DatePickerDark */
/* start overwritten DatePickerDark */
.development .customDatePickerDevelopmentDark {
    border: 2px solid var(--dark-version-one);
    height: 50px;
    border-radius: 10px;
    padding: 5px 20px;
    background-color: var(--dark-version-one);
    color: white;
    font-weight: 600;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper input {
    color: white;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper input::placeholder {
    color: white;
    background-color: transparent;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    background-color: transparent!important;
    color: white!important;
    fill: white;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button svg {
    color: white!important;
    stroke: white!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button svg:hover {
    color: rgb(202, 202, 202)!important;
    stroke:  rgb(202, 202, 202)!important;
    transition: stroke 2s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark {
    background-color: var(--dark-version-one)!important;
    border: 1px solid var(--dark-version-one);
    overflow: hidden;
    color: white!important;
    border-radius: 10px;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation {
    border: none!important;
    box-shadow: none!important;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation button {
    color: white;
    background-color: transparent;
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    font-weight: 600;
    border-radius: 50%;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation button:hover {
    background-color: var(--dark-version-two);
    transition: background-color 2s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation__label {
    background-color: transparent!important;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button {
    /* color: white; */
    font-weight: 300;
    border-radius: 5px;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button:hover {
    background-color: var(--dark-version-three)!important;
    transition: background-color 1s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button.react-calendar__tile--active {
    background-color: var(--color-information);
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button.react-calendar__tile--now {
    background-color: var(--dark-version-three);
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__month-view__days__day--weekend {
    /* background-color: var(--color-error); */
    color: var(--color-error);
    font-weight: 400;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
}
/* ends overwritten DatePickerDark */
/* ends overwritten DatePickerDark */
/* ends overwritten DatePickerDark */

/* start overwritten DatePickerLight */
/* start overwritten DatePickerLight */
/* start overwritten DatePickerLight */
.development .customDatePickerDevelopmentLight {
    border: 1px solid var(--light-version-one);
    height: 50px;
    min-width: 220px;
    border-radius: 0px;
    padding: 5px 20px;
    background-color: white;
    color: black;
    font-weight: 400;
    font-size: 18px;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper input {
    color: black;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper input::placeholder {
    color: black;
    background-color: transparent;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    background-color: transparent!important;
    color: black!important;
    fill: black;
    font-size: 18px;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button svg {
    color: black!important;
    stroke: black!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button svg:hover {
    color: rgb(202, 202, 202)!important;
    stroke:  rgb(202, 202, 202)!important;
    transition: stroke 2s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight {
    background-color: white!important;
    border: 1px solid var(--light-version-one);
    margin-left: -1px;
    overflow: hidden;
    border-radius: 0px;
    padding: 20px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 18px;
    color: var(--color-error)!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation {
    border: none!important;
    box-shadow: none!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation button {
    color: black;
    background-color: transparent;
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    font-weight: 300;
    border-radius: 0px;
    font-size: 18px;
    text-transform: uppercase;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation button:hover {
    background-color: var(--light-version-two);
    transition: background-color 2s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation__label {
    background-color: transparent!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button {
    font-weight: 300;
    border-radius: 0px;
    color: black;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button:hover {
    background-color: var(--light-version-three)!important;
    transition: background-color 1s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button.react-calendar__tile--active {
    background-color: var(--color-information);
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button.react-calendar__tile--now {
    background-color: var(--light-version-three);
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__month-view__days__day--weekend {
    color: var(--color-error);
    font-weight: 300;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
}
/* ends overwritten DatePickerLight */
/* ends overwritten DatePickerLight */
/* ends overwritten DatePickerLight */

.development .colorDevelopment {
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: var(--white-light);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    margin: 5px;
}

.development .lineColorDevelopment {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


/* start overwritten DatePickerOriginalWeekend */
/* start overwritten DatePickerOriginalWeekend */
/* start overwritten DatePickerOriginalWeekend */
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(7),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(14),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(21),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(28),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(35),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(42) {
    color: black;
}

.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(1),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(8),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(15),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(22),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(29),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(36) {
    color: var(--color-error)!important;
    font-weight: 700;
}
/* ends overwritten DatePickerOriginalWeekend */
/* ends overwritten DatePickerOriginalWeekend */
/* ends overwritten DatePickerOriginalWeekend */