.permissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.permissions .warningPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.permissions .scrollPermissions {
    overflow: scroll;
    height: 100%;
}

.permissions .headerPermissions {
    margin-top: 20px;
}

.permissions .headerPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.permissions .headerPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);

}

.permissions .bodyPermissions {
    margin-bottom: 250px;
    overflow: scroll;
}

.permissions .bodyPermissions .boxBodyPermissions {
    min-width: 1000px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions {
    padding-bottom: 20px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions { 
    width: 100%;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions .buttonPlusPermissionsGroupPermisions {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions .columnTitleHeaderConfigPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.permissions .bodyPermissions .lineBodyConfigPermissions.pair {
    background-color: var(--white-dark-three);
}

.permissions .bodyPermissions .lineBodyConfigPermissions.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.permissions .bodyPermissions .lineBodyConfigPermissions {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}