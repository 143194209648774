.header {  
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    min-height: 56px;
}

.header .colHeader{  
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.header .colHeader .title{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    font-weight: 600;
    padding: 16px 0px 16px 20px;
}

.header .colHeader .buttonMoreHeader {
    position: relative;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #08a742;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease 0s;
    color: var(--white-light);
}

.header .colHeader .buttonMoreHeader:hover {
    background-color: #08a742e0;
    color: var(--white-light);
}

.header .colHeader .buttonProfileHeader {
    border: 4px solid transparent;
    margin-right: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    color: #1372f4;
    font-size: 32px;
}

.header .colHeader .buttonProfileHeader:hover {
    border: 4px solid #e5e5e5;    
    transition: border .2s;
}

.header .colHeader .nameProfileHeader {
    color: var(--primary-color);
    font-weight: 600;
    margin-right: 20px;
}

.header .colHeader .areaAvatarHeader {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

.header .colHeader .areaAvatarHeader .avatarHeader {
    height: 100%;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader .icon{
    margin-right: 15px;
    margin-left: 10px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover .icon{
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;
}

.header .colHeader .dropProfileHeader {
    z-index: 900;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader .icon {
    margin-right: 15px;
    margin-left: 20px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover .icon {
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;   
}

.header .colHeader .dropProfileHeader .titleProfileHeader {
    color: var(--dark-dark-three);
    margin-top: 14px;
    margin-bottom: 8px;
    margin-left: 30px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.header .colHeader .dropProfileHeader .divider {
    height: 1px;
    width: 100%;
    content: " ";
    display: block;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.header .colHeader .anchorProfileSubmenuHeader {
    position: relative;
}

.header .colHeader .dropProfileHeader .dropProfileSubmenuHeader {
    left: 20px;
    top: 40px;
    position: absolute;
}

.header .colHeader .dropProfileSubmenuHeader .buttonProfileSubmenuHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0;
    padding-left: 0px;
}

.header .colHeader .dropProfileSubmenuHeader .buttonProfileSubmenuHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;   
}