.itemHistoricActivity {
    background-color: transparent;
    width: 100%;
    min-height: 110px;
}

.itemHistoricActivity.fileActivity {
    height: auto;
    min-height: auto;
}

.itemHistoricActivity.fileActivity .fileActivityColumn + .fileActivityColumn {
    border-top: 1px solid var(--white-dark-two) !important;
}

/* start all style in progress */
/* start all style in progress */
/* start all style in progress */
.itemHistoricActivity .lineProgressItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    padding-top: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 36px;
    height: 36px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .lineProgressTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    padding-top: 5px;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    border-style: dashed;
    margin-left: 16px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid var(--white-dark-two);
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 10px;
    position: relative;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity {
    position: absolute;
    right: 15px;
    top: 0;
    
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity:hover {
    background-color: var(--white-dark);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity {
    position: absolute;
    top: 40px;
    right: 5px;
    z-index: 800;
    width: 250px;
    min-height: 140px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    display: block;
    width: 16px;
    height: 16px;
    content: " ";
    background-color: var(--white-light);
    position: absolute;
    top: 10px;
    left: -8px;
    border: 1px solid var(--white-dark-two);
    border-right: none;
    border-top: none;
    transform: rotate(45deg);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity {
    width: initial;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity {
    position: relative;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity:hover .helpCheckFinishedItemHistoricActivity {
    display: block;

}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity .helpCheckFinishedItemHistoricActivity {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    min-width: 140px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck {
    border-radius: 50%;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck:hover {
    border-color: var(--color-success);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonArchivedFileItemHistoricActivity {
    font-size: 20px;
    color: var(--dark-light);
    background-color: transparent;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

/* ends all style in progress */
/* ends all style in progress */
/* ends all style in progress */


/* start all styles finished */
/* start all styles finished */
/* start all styles finished */

.itemHistoricActivity .lineFinishedItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .iconFinishedTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 24px;
    height: 24px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    margin-left: 5px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    margin-left: 16px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity.disabled {
    border: none;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: transparent;
    flex-direction: column;
    border-radius: 5px;
    position: relative;
}

.itemHistoricActivity .columnDetailsItemHistoricActivity .fileIcon {
    font-size: 20px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

.itemHistoricActivity .activityAnnotations {
    display: flex;
    width: calc(100% + 15px);
    background-color: #FFFCDC;
    padding: 10px 0;
    border-top: 1px solid var(--white-dark-two);
    margin-left: -15px;
    padding-left: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 5px;
}

.itemHistoricActivity .iconCreatorSeparator {
    font-size: 3px;
    margin: 0 10px;
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity{
    border-color: var(--color-error);
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity{
    background-color: var(--color-error);
    color: var(--white-light);
    border-color: var(--white-light);
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    border-color: var(--color-error);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity{
    border-color: var(--color-success);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity{
    background-color: var(--color-success);
    color: var(--white-light);
    border-color: var(--white-light);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    border-color: var(--color-success);
}

/* ends all styles finished */
/* ends all styles finished */
/* ends all styles finished */