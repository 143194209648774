.room {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.room .headerCompanyPreferences {
    margin-top: 20px;
}

.room .headerRoom {
    border: 2px solid red;
}

.room .bodyRoom {
    background-color: transparent;
    height: calc(100vh - 56px - 56px); /* 56px by header component and 100px footer section*/
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    position: relative;
    background-color: var(--white-dark-two);
}

.room .buttonRoom {
    width: 100px;
    font-size: 14px;
    padding: 8px 12px;
    margin: 10px;
}

.room .buttonIconRoom {
    border-radius: 50%;
    margin: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #404346;
    z-index: 300;
}

.room .buttonIconRoom.maximum {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
}

.room .buttonIconRoom.messages {
    position: absolute;
    top: 20px;
    right: 20px;
}

.room .buttonIconRoom.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.room .inputRoom {
    max-width: 250px;
}

.room .bodyCreateRoom {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    height: calc(100vh - 56px);
}

.room .bodyRoom .areaVideosRoom {
    width: 70%;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: var(--white-light);
    border-radius: 5px;
    position: relative;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 300px;
    width: max-content;
    margin: 5px;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat:hover .buttonIconRoom.maximum {
    display: flex;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat.hidden {
    display: none;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .nameRemoteUserChat {
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.6);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--white-light);
    text-align: center;
    padding: 4px;
    z-index: 300;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat {
    background-color: transparent;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat video {
    height: 100%;
    max-width: 250px;
    object-fit: cover;
    border-radius: 5px;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat video.mySelf {
    transform: scaleX(-1);
}

.room .bodyRoom .areaMessagesRoom {
    width: 30%;
    background-color: var(--white-light);
    border-radius: 5px;
    margin-left: 10px;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom {
    height: calc(100% - 60px);
    overflow: scroll;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: max-content;
    max-width: 80%;
    margin: 5px;
    padding: 10px;
    word-wrap: break-word;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem .author {
    background-color: transparent;
    font-size: 14px;
    color: var(--dark-dark);
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem .content {
    background-color: transparent;
    color: #000;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.left {
    margin-right: auto;
    background-color: #dc35468d;
    border-radius: 5px;
    border-top-left-radius: 0;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.right {
    margin-left: auto;
    background-color: #28a74681;
    border-radius: 5px;
    border-top-right-radius: 0;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.hidden {
    display: none;
}    

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom {
    flex-direction: row;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-top: 4px solid var(--white-dark-two);
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom {
    height: 100%;
    width: calc(100% - 56px);
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom .inputInputGeneral {
    height: 100%;
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom .inputInputGeneral .fieldInputGeneral {
    height: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    border-bottom-left-radius: 5px;
}

.room .footerRoom {
    height: 56px;
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.room .modalMaximumStream {
    height: calc(100vh - 56px);
    width: calc(100vw - 56px);
    background-color: var(--white-dark-two);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    position: relative;
}

.room .modalMaximumStream.hidden {
    display: flex;
}

.room .modalMaximumStream.hidden {
    display: none;
}

.room .modalMaximumStream video {
    border-radius: 5px;
}

.room .modalMaximumStream .nameMaximumStream {
    font-size: 18px;
    color: #404346;
    position: absolute;
    top: 20px;
    left: 20px;
}

.room .modalMaximumStream .videoMaximumStream.mySelf {
    transform: scaleX(-1); 
}

@media (max-width: 767px)
{
    .room .bodyRoom {
        height: calc(100vh - 56px - 56px - 56px);
    }

    .room .bodyRoom .areaVideosRoom {
        display: none!important;
    }

    .room .bodyRoom .areaMessagesRoom {
        width: 100%!important;
        display: block!important;
    }
}