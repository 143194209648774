.menus {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--white-dark-two);
}

.menus .buttonMenus {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: var(--dark-dark-three);
    padding: 10px 13px;
    height: 47px;
    
}

.menus .buttonMenus:hover {
    background-color: var(--white-dark);
    color: var(--primary-color);
}

.menus .buttonMenus.active {
    color: var(--color-focus);
    border-bottom: 2px solid var(--color-focus);
    background-color: #eff5fd;
}

.menus .buttonMenus:hover.active {
    background: #d3e4fa;
}

.menus .buttonMenus .icon {
    margin-right: 10px;
}