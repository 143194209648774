.subtitleAnimation.anchor {
    position: relative;
    min-height: 30px; /*DANGER: don't change this*/
    max-height: 90px; /*DANGER: don't change this*/
    min-width: 10px;
    max-width: 300px;
    background-color: transparent;
}

.subtitleAnimation {
    border: none;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: black;
    margin: 5px;
    color: var(--white-light);
    padding: 4px 6px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    cursor: default;
    user-select: none;
    position: absolute;
    top: 10px;
    height: 100%;
    animation-name: animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.subtitleAnimation.container.warning {
    background-color: var(--color-warning);
}

.subtitleAnimation.container.success {
    background-color: var(--color-success);
}

.subtitleAnimation.container.information {
    background-color: var(--color-information);
}

.subtitleAnimation.container.focus {
    background-color: var(--color-focus);
}

.subtitleAnimation.container.inactive {
    background-color: var(--color-inactive);
}

.subtitleAnimation.container.error {
    background-color: var(--color-error);
}

.subtitleAnimation.container.inactive {
    background-color: var(--color-inactive);
}

.subtitleAnimation.container .contentSubtitleAnimation {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.subtitleAnimation.container::before {
    content: " ";
    display: block;
    right: -6px;
    position: absolute;
    border-top: 15px solid transparent;
    border-left: 14px solid black;
    transform: rotate(-137deg);
    top: 7px;
    border-radius: 4px;
}

.subtitleAnimation.lines2.container::before {
    top: 14px;
}

.subtitleAnimation.lines3.container::before {
    top: 24px;
}

.subtitleAnimation.lines4.container::before {
    top: 30px;
}

.subtitleAnimation.container.warning::before {
    border-left: 14px solid var(--color-warning);
}

.subtitleAnimation.container.success::before {
    border-left: 14px solid var(--color-success);
}

.subtitleAnimation.container.information::before {
    border-left: 14px solid var(--color-information);
}

.subtitleAnimation.container.focus::before {
    border-left: 14px solid var(--color-focus);
}

.subtitleAnimation.container.inactive::before {
    border-left: 14px solid var(--color-inactive);
}

.subtitleAnimation.container.error::before {
    border-left: 14px solid var(--color-error);
}

@keyframes animation
{
    0%   {top: 3px;}
    50%  {top: -3px;}
    100%  {top: 3px;}
}