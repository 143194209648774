.containerCustomCarousel .carousel-control-prev,
.containerCustomCarousel .carousel-control-next {
  background-color: #ffffff1c;
  border: none;
  border-radius: 50%;
  margin: 0 10px;
  color: #959191;
  width: 40px;
  font-size: 25px;
  height: 40px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.containerCustomCarousel .carousel-control-prev-icon,
.containerCustomCarousel .carousel-control-next-icon {
  background: none;
  font-size: 1.5rem;
  color: #ffffff;
  transition: color 0.3s ease;
}

.containerCustomCarousel .carousel-control-prev:hover,
.containerCustomCarousel .carousel-control-next:hover {
  color: var(--color-focus);
}

.containerCustomCarousel .carousel-control-prev .visually-hidden,
.containerCustomCarousel .carousel-control-next .visually-hidden {
  display: none !important;
}

.containerCustomCarousel .carousel-control-prev span,
.containerCustomCarousel .carousel-control-next span {
  display: none !important;
}

.containerCustomCarousel iframe {
  pointer-events: none;
}

.containerCustomCarousel .carousel-caption {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 15px 25px;
  max-width: 80%;
  text-align: center;
}

.containerCustomCarousel .carousel-caption h3 {
  font-size: 1.8rem;
  font-weight: bold;
}

.containerCustomCarousel .carousel-caption p {
  font-size: 1rem;
  margin-top: 5px;
}

.containerCustomCarousel .carousel-item img {
  max-height: 700px;
  object-fit: cover;
  filter: brightness(90%);
  transition: filter 0.3s ease;
}

.containerCustomCarousel .carousel-item img:hover {
  filter: brightness(100%);
}

.containerCustomCarousel .carousel-indicators [data-bs-target] {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  border: 1px solid #a7a7a7;
  margin: 0 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.containerCustomCarousel .carousel-indicators [data-bs-target].active {
  background-color: var(--color-focus);
}
