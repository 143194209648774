@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.itemListWhatsApp {
    height: 72px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.itemListWhatsApp .buttonItemListWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.itemListWhatsApp:hover {
    background-color: #f5f6f6;
    cursor: pointer;
    transition: background-color 0.25s;
}

.itemListWhatsApp.opened {
    background-color: #f0f2f5;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp {
    background-color: #dfe5e7;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
    margin: 0 15px 0 13px;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp .photoContactItemListWhatsApp {
    height: 100%;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp .chatListeItemIconAvatarWhatsApp {
    color: #fff;
    font-size: 40px;
    margin-bottom: -12px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp {
    border-top: 1px solid #e9edef;
    height: 100%;
    width: calc(100% - 77px); /* 509px by chatListWhatsApp component plus 77px by chatAvatarItemListWhatsApp component */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
    padding-right: 15px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp {
    display: inline-flex;
    width: 100%;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .areaIconVerifiedContactItemListWhatsApp {
    margin-top: 1px;
    margin-left: 3px;
    display: flex;
    align-items: flex-start;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .areaIconVerifiedContactItemListWhatsApp .iconVerifiedContactItemListWhatsApp {
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .nameContactItemListWhatsApp {
    color: #111b21;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .timeContactItemListWhatsApp {
    color: #667781;
    font-size: 12px;
    margin: 3px 0 0 6px;
    font-weight: 400;
    width: 114px;
    text-align: right;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp {
    width: 100%;
    height: 24px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .amountNotReadMessagesItemListWhatsApp {
    background-color: #25d366;
    font-weight: 500;
    max-width: 100%;
    margin-left: 6px;
    font-size: 12px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    color: #fff;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp.deleted {
    align-items: center;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .messageItemListWhatsApp {
    color: #667781;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    flex-grow: 1;
    width: 1px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .messageItemListWhatsApp.deleted {
    /* font-style: italic; */
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp {
    margin-right: 2px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.read {
    fill: #53bdeb;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.delivered {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.sent {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.deleted {
    margin: 0;
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.videoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.photoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.documentRead {
    fill: #53bdeb;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.videoNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.photoNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.documentNoRead {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioRead {
    margin: 0;
    margin-right: 3px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioNoRead {
    margin: 0;
    margin-right: 3px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp {
    position: relative;
    opacity: 0;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100%;
}

.itemListWhatsApp:hover .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    transition: width 3s;
    opacity: 1;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp .iconDropItemListWhatsApp {
    position: absolute;
    margin-right: -8px;
}

.itemListWhatsApp:hover .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp .iconDropItemListWhatsApp {
    margin-right: 0px;
    transition: margin-right 0.15s;
}