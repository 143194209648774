.login {
    max-width: 1024px;
    padding: 0;
}

.login .navigation {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.login .navigation .content {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.login .navigation .brand {
    font-size: 42px;
    text-decoration: none;
    color: #26292c;
    font-weight: 700;
}

.login .navigation .content .information {
    font-size: 15px;
    margin-right: 20px;
    color: rgba(38,41,44,0.88);
    font-weight: 300;
    font-family: "SourceSansPro", sans-serif;
}

.login .navigation .content .anchor {
    color: var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    background: transparent;
    padding: 6px 15px;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.login .form {
    background-color: var(--white-light);
    background-color: #fff;
    max-width: 500px;
    margin-top: 40px;
    padding: 40px 60px 52px;
}

.login .form .title {
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #26292c;
    font-size: 34px;
    text-align: center;
    line-height: 1.2em;
    margin: 0 0 40px 0;
    font-weight: 900;
}

.login .form .group { 
    min-height: 83px;
    margin-bottom: 25px;
}

.login .form .group .anchorForgotPassword{ 
    text-decoration: none;
    position: absolute;
    right: 0;
    color: #888e94;
    text-decoration: none;
    font-size: 12px;
    z-index: 600;
    cursor: pointer;
}

.login .form .anchorLogin{ 
    text-decoration: none;
    color: #888e94;
    text-decoration: none;
    font-size: 12px;
    z-index: 600;
    cursor: pointer;
}

.login .form .group .buttonLogin{ 
    margin-bottom: 25px;
}

.login .form .spanError {
    visibility: hidden;
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.5em;
    text-align: left;
    color: var(--color-error);
    min-height: 1.5em;
}

.login .footer {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.login .footer .linkSupport{
    color: #747678;
    text-decoration: none;
}

.login .footer .linkCRM{
    color: #26292c;
    text-decoration: none;
}

@media (max-width: 767px)
{
    .login .navigation {
        padding-left: 6px;        
    }

    .login .form {
        margin-top: 0px;
        padding: 40px 20px 20px 20px;
    }

    .login .form .title {
        font-size: 24px;
    }

    .login .navigation .content .anchor{
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.5em;
    }

}