.buttonDetailsAndSelectPanelWhatsApp {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .titleButtonDetailsAndSelectPanelWhatsApp {
    color: rgb(17, 27, 33);
    font-size: 16px;
    font-weight: 400;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp svg {
    fill: rgb(102, 119, 129);
    margin-right: 8px;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .amountButtonDetailsAndSelectPanelWhatsApp {
    color: rgb(102, 119, 129);
    margin-right: 8px;
    font-size: 15px;
    font-weight: 400;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp svg {
    fill: rgb(102, 119, 129);
}