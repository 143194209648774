.serviceQueue {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.serviceQueue .warningServiceQueue {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.serviceQueue .scrollServiceQueue {
    overflow: scroll;
    height: 100%;
}

.serviceQueue .headerServiceQueue {
    margin-top: 20px;
}

.serviceQueue .headerServiceQueue h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.serviceQueue .headerServiceQueue h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-error);
    transform: rotate(45deg);

}

.serviceQueue .bodyServiceQueue  {
    /* nothing */
}

.serviceQueue .bodyServiceQueue .lineTitleHeaderServiceQueue {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueue .bodyServiceQueue .lineTitleHeaderServiceQueue .columnTitleHeaderServiceQueue {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue {
    background-color: hsla(134, 61%, 50%, 0.527);
    cursor: grab;
    border-bottom: 1px solid ;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .companyUnitServiceQueue {
    border: 2px solid var(--dark-dark-three);
}


.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: auto;
    margin-right: auto;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .iconFilterBodyServiceQueue {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue {
    width: 100%;
    border: none;
    cursor: pointer;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .optionsSelect {
    min-width: 200px;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .fieldSelect {
    border: none;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue.frozen {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit {
    display: flex;
    justify-content: flex-start;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit .partialCompanyUnitServiceQueue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit .fullCompanyUnitServiceQueue {
    display: none;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -10px;
    left: 0;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit:hover .fullCompanyUnitServiceQueue {
    display: inline-flex;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue .buttonBodyConfiServiceQueue {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue .buttonBodyConfiServiceQueue:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}

.serviceQueue .footerServiceQueue {
    margin-bottom: 250px;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue {
    width: 100%;
    border-bottom: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue.success {
    background-color: hsla(134, 61%, 50%, 0.527);
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue.error {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue {
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}

.serviceQueue .footerServiceQueue .lineTitleFooterServiceQueue {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueue .footerServiceQueue .lineTitleFooterServiceQueue .columnTitleFooterServiceQueue {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue span {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue.reason {
    position: relative;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue .partialReasonServiceQueue {
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    text-overflow: ellipsis;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue .fullReasonServiceQueue {
    display: none;
    cursor: default;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue.reason:hover .fullReasonServiceQueue {
    display: block;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -40px;
    left: 0;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}