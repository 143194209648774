.chat {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.chat .headerCompanyPreferences {
    margin-top: 20px;
}

.chat .headerChat {
    border: 2px solid red;
}

.chat .bodyChat {
    background-color: transparent;
    height: calc(100vh - 56px - 56px); /* 56px by header component and 100px footer section*/
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    position: relative;
}

.chat .bodyChat .boxChat {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    min-width: 400px;
}

.chat .bodyChat .boxChat .labelChat {
    margin-top: 20px;
}

.chat .buttonChat {
    width: 100px;
    font-size: 14px;
    padding: 8px 12px;
    margin: 10px;
}

.chat .inputChat {
    max-width: 250px;
}

.chat .bodyChat {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    height: calc(100vh - 56px);
}

.chat .bodyChat .brandChat {
    color: var(--color-focus);
    font-size: 60px;
    margin-bottom: 20px;
}

.chat .footerChat {
    height: 56px;
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}