.cardButonsTopLineChart {
    padding: 20px;
    margin: 10px 18px 10px 18px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
    position: relative;
}

.cardButonsTopLineChart .filterLoadingCardButtonsTopLineCharts {
    background-color: rgba(0, 0, 0, 0.396);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.cardButonsTopLineChart .filterLoadingCardButtonsTopLineCharts .loadingCardButtonsTopLineChart {
    background-color: transparent;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart {
    position: relative;
    display: flex;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart {
    
}

.cardButonsTopLineChart .lineCardButonsTopLineChart.chart {
}

.cardButonsTopLineChart .lineCardButonsTopLineChart.buttons {
    display: flex;
    overflow: scroll;
    padding-left: 5px;
    padding-right: 5px;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .columnCardButonsTopLineChart {
    flex-direction: row;
    display: flex;
    width: 0;
    padding-left: 30px; /* same value of the width buttonCarousel */
    padding-right: 30px; /* same value of the width buttonCarousel */
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart {
    width: 0px; 
    height: 120px;
    padding: 10px;
    background-color: var(--white-dark-three);
    margin-left: 5px;
    margin-right: 5px;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart.previous {
    top: 0;
    left: 0;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart.next {
    top: 0;
    right: 0;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart .iconButtonIcon {
    color: var(--primary-color);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
    flex-direction: column;
    min-width: 140px!important;
    height: 120px;
    padding: 10px 15px 0 15px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart::before {

}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart:hover {
    background-color: var(--white-dark-three);
    transition: background-color 1s;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .labelButtonToggleCardButtonsTopLineChart {
    font-size: 16px;
    color: var(--dark-dark-three);
    font-weight: 400;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .labelButtonToggleCardButtonsTopLineChart {
    font-weight: 600;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .amountButtonToggleCardButtonsTopLineChart {
    font-size: 15px;
    color: var(--dark-dark-three);
    font-weight: 400;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart.success {
    color: var(--color-success);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart.information {
    color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .performanceButtonToggleCardButtonsTopLLineChart {
    margin: 0;
    margin-left: -16px;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active::before {
    position: absolute;
    top: 0px;
    left: 20px;
    display: block;
    content: " ";
    width: 100px;
    height: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .labelButtonToggleCardButtonsTopLineChart {
    color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart {
    color: #000;
}