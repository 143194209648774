.dropdownWhatsApp {
    min-width: 230px;
    max-width: 340px;
    min-height: 100px;
    background-color: #fff;
    position: absolute;
    z-index: 10000;
    opacity: 0;
    text-align: left;
    padding: 9px 0;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(11,20,26,.26),0 2px 10px 0 rgba(11,20,26,.16);
    font-size: inherit;
    transform: scale(0.5);
}

.dropdownWhatsApp.active {
    opacity: 1;
    transform-origin: right top;
    transform: scale(1);
    transition:  transform 0.28s;
}

.dropdownWhatsApp.active.activeLeftTop {
    transform-origin: left top;
}

.dropdownWhatsApp.active.activeLeftBottom {
    transform-origin: left bottom;
}

.dropdownWhatsApp.active.activeRightTop {
    transform-origin: right top;
}

.dropdownWhatsApp.active.activeRightBottom {
    transform-origin: right bottom;
}

.dropdownWhatsApp.inactive {
    opacity: 0;
    z-index: -1;
}