.detailsService {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsService .warningDetailsService {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsService .headerNavigationDetailsService {
}

.select.servicesConfirmationDetailsService .optionsSelect { /* WARNING: Don't have reference with .detailsService, because is modal*/
    position: relative;
}

.detailsService .scrollDetailsService {
    overflow: scroll;
    height: 100%;
}

/* start styles header */
/* start styles header */
/* start styles header */
.detailsService .headerDetailsService {
    background-color: var(--white-light);
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 2px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService {
    margin-bottom: 16px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService {
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalEditTitleDetailsService {
    position: absolute;
    top: 30px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService {
    position: absolute;
    top: 50px;
    z-index: 800;
    width: 250px;
    min-height: 120px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService .buttonMoreActionsDetailsService {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService .buttonMoreActionsDetailsService:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalEditMoneyDetailsService {
    position: absolute;
    top: 80px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .titleHeaderInfoDetailsService {
    color: var(--color-focus);
    font-size: 27px;
    font-weight: 400;
    display: initial;
    cursor: pointer;
    margin: 0;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .titleHeaderInfoDetailsService:hover {
    background-color: #fffcdc;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .nameStampHeaderDetailsService { 
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .nameStampHeaderDetailsService.warning {
    color: black;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .modalEditStampDetailsService { 
    position: absolute;
    top: 30px;
    width: 300px;
    min-height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .notificationStampDetailsService{
    display: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService:hover .notificationStampDetailsService{
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .buttonStampHeaderDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .buttonStampHeaderDetailsService:hover {
    background-color: var(--white-dark);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService {
    margin-top: 12px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-start;
    line-height: 30px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .moneyInfoDetailsService {
    line-height: 24px;
    font-weight: 400;
    font-size: 23px;
    color: var(--primary-color);
    cursor: pointer;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .moneyInfoDetailsService:hover {
    background-color: #fffcdc;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService::before {
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 1px;
    min-height: 25px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .iconUserMoreInfoDetailsService {
    font-size: 18px;
    color: #404346;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .nameUserMoreInfoDetailsService {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .buttonPlusBusinessMoreInformationDetailsService.positive {
    min-width: 50px;
    max-width: 120px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 10px;
    margin-bottom: 0px;

}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService {
    min-width: 32px;
    max-width: 100px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: var(--white-light);
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 0px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService:hover {
    opacity: 0.8;
    transition: opacity 0.8s;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.positive {
    background-color: var(--color-success);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.negative {
    background-color: var(--color-error);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.primary {
    background-color: var(--color-focus);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.warning {
    background-color: var(--color-warning);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.default {
    background-color: var(--white-light);
    color: var(--dark-dark);
    border: 1px solid var(--dark-light);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonMoreActionsHeaderInfoDetailsService {
    height: 32px;
    width: 32px;
    background: #fff;
    border: 0;
    border-radius: 2px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary-color);
    border: 1px solid #cbcccd;

}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonMoreActionsHeaderInfoDetailsService:hover {
    background-color: var(--white-dark);
    transition: background-color .12s;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .dividerMoreActionsDetailsService {
    width: 100%;
    height: 1px;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .avatarOwnerHeaderDetailsService {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0 5px 0 10px;
    line-height: 18px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .notificationOwnerDetailsService {
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService:hover .notificationOwnerDetailsService {
    display: block;    
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .modalEditOwnerDetailsService{
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 140px;
    z-index: 800;
    text-align: left;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .anchorNameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .anchorNameOwnerHeaderDetailsService:hover {
    text-decoration: underline!important;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .levelNameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-light);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .buttonEditOwnerHeaderDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    margin-right: 10px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .buttonEditOwnerHeaderDetailsService:hover {
    background-color: var(--white-dark);
}

/* ends styles header */
/* ends styles header */
/* ends styles header */


/* start styles body */
/* start styles body */
/* start styles body */
.detailsService .bodyDetailsService {
    height: 100%;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService {
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService {
    border-bottom: 1px solid #e5e5e5;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .buttonEditPersonOthersDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .buttonEditPersonOthersDetailsService:hover {
    background-color: var(--white-dark);
}


.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .subtitleOthersDetailsService {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: block;
    
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .lineBodyOthersDetailsService {
    margin: 8px 0;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne {
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne .avatarClientOthersDetailsService {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-focus);
    fill: var(--color-focus);
    border: 2px solid rgba(38,41,44,.64);
    box-sizing: border-box;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne span {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    font-weight: 600;
    color: rgba(38,41,44,.64);
    text-align: right;
    word-wrap: break-word;
    margin: 2px 0;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span {
    overflow-wrap: anywhere;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span.nameClientBodyOthersDetailsService{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span {
    color: var(--primary-color);
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService span.minumus {
    color: #747678;
    font-size: 13px;
    line-height: 9.2px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService.general {
    margin-top: 15px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .progressOthersDetailsService {
    width: 100%;
    display: block;
    content:  " ";
    background-color: var(--color-success);
    border-radius: 120px;
    height: 16px;
    margin-bottom: 10px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .progressOthersDetailsService:hover {
    transform: scaleX(1.2);
    transform: scaleY(1.3);
}

.detailsService .bodyDetailsService .boxActivitiesBodyDetailsService {
    padding: 0;
}

.detailsService span.businessStatus {
    height: 32px;
    font-weight: 700;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    color: var(--white-light);
}

.detailsService span.businessStatus.danger {
    background-color: var(--color-error);
}

.detailsService span.businessStatus.success {
    background-color: var(--color-success);
}

.detailsService .bodyDetailsService .menusActivitiesDetailsService {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.detailsService .bodyDetailsService .boxActivitiesHistoricBodyDetailsService {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    /* margin-bottom: 200px;
    min-height: 1000px; */
}

.detailsService .bodyDetailsService .boxActivitiesHistoricBodyDetailsService .statusActivitiesDetailsService {
    background-color: #747678;
    border-radius: 180px;
    color: white;
    padding: 1px 20px;
    margin: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}


.detailsService .buttonLinkDetailsService {
    background-color: #FFF;
    color: #317AE2;
    font-weight: 400;
    padding: 0;
    font-size: 16px;
    justify-content: flex-start;
}

.detailsService .buttonActivitiesBusinessRecovered {
    font-size: 16px;
    background-color: var(--color-focus);
}

.detailsService .titleActivitiesBusinessRecovered {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
}

.detailsService .remittance-status {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    margin-top: 5px;
    cursor: pointer;
}

/* ends styles body */
/* ends styles body */
/* ends styles body */


@media (min-width: 767px) and (max-width: 1024px)
{
    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .modalEditStampDetailsService {
        position: fixed;
        top: 30px;
        left: 60px;
    }
}

@media (max-width: 425px)
{
    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService {
        flex-direction: column;        
    }

    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService::before {
        display: none;
    }
}