.containerFileUpload {
    /* width: fit-content; */
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}

.headerFileUpload {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1rem;
}

.fileUploadLogoCircle {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e9e5ff;
  fill: #1cc972;
}

.fileUploadLogoCircle i{
  color: var(--color-success);
  font-size: 26px;
}

.fileUploadCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.fileUploadModalBody {
  padding: 1rem 1.5rem;
}

.fileUploadTitle {
  font-weight: 700 !important;
}

.fileUploadModalDescription {
  color: #6a6b76;
}

.fileUploadArea {
  margin-top: 1.25rem;
  background-color: transparent;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed #6a6b76;
  border-radius: 5px;
}

.fileUploadArea .fileInfo p {
    font-size: 13px !important;
}

.fileUploadArea.dragOver {
    border: 2px solid #1cc972;
    background-color: rgba(28, 201, 114, 0.1);
  }
  
  .fileUploadArea:hover,
  .fileUploadArea:focus {
    cursor: pointer;
    border: 1px solid #6a6b76;
  }
  

.fileUploadArea:hover,
.fileUploadArea:focus {
  cursor: pointer;
  border: 1px solid #6a6b76;
}

.fileUploadAreaIcon {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  fill: #1cc972;
}

.fileUploadAreaIcon i{
  color: var(--color-success);
  font-size: 36px;
}

.fileUploadAreaTitle {
  margin-top: 1rem;
  display: block;
  font-weight: 700;
  color: #0d0f21;
}

.fileUploadAreaDescription {
  display: block;
  color: #6a6b76;
}

.fileUploadAreaDescription strong {
  color: #1cc972;
  font-weight: 700;
}

.fileUploadModalFooter {
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.containerFooterFileUpload {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
}

.containerFooterFileUpload .buttonFileUpload {
    width: 180px;
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerFooterFileUpload .buttonCancelFileUpload {
    background-color: var(--color-warning);
}
