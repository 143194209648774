.tableEditActivities {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditActivities .helpTableEditActivities {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditActivities table, tr, th, td {
    margin: 0;
}

.tableEditActivities table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditActivities table .buttonCheckTableEditActivities {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditActivities table .buttonCheckTableEditActivities .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table .buttonFinishTableEditActivities {
    width: initial;
    padding-left: 10px;
    border: 1px solid #eee;
    border-right: none;
    border-bottom: none;
    border-left: none;
    height: 100%;
    width: 100%;
}

.tableEditActivities table .buttonFinishTableEditActivities {
    position: relative;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck {
    border-radius: 50%;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck:hover {
    border-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck {
    border: 2px solid var(--color-success);
    background-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditActivities table tr {
    height: 32px;
}

.tableEditActivities .theadTableEditActivities td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditActivities .theadTableEditActivities td.minimus {
    width: 90px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus45 {
    width: 45px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus60 {
    width: 60px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus75 {
    width: 75px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus120 {
    width: 120px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus180 {
    width: 180px!important;
}

.tableEditActivities .theadTableEditActivities td:last-of-type {
    border-right: none;
}

.tableEditActivities td.checkTableEditActivities {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditActivities td.finishTableEditActivities {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 25px!important;
}

.tableEditActivities .tbodyTableEditActivities:hover {
    background-color: var(--white-dark);
}

.tableEditActivities .tbodyTableEditActivities:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditActivities .tbodyTableEditActivities td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditActivities .table .tbodyTableEditActivities td.checkTableEditActivities { 
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditActivities .table .tbodyTableEditActivities td.finishTableEditActivities {
    border: 1px solid #eee;
    position: relative;
    align-items: center;
}

.tableEditActivities .tbodyTableEditActivities td:hover .buttonEditTableEditActivities {
    opacity: 1;
}

.tableEditActivities .tbodyTableEditActivities td:hover .editTableEditActivities {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditActivities .tbodyTableEditActivities td .stampEditActivities {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities .modalFieldEdit {
    z-index: 1000;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.error i.iconFieldEdit {
    color: var(--color-error)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.warning i.iconFieldEdit {
    color: var(--color-warning)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.success i.iconFieldEdit {
    color: var(--color-success)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.inactive i.iconFieldEdit {
    color: var(--color-inactive)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.justifyCenter {
    justify-content: center;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.inheritIconColor i {
    color: inherit;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities button.button.buttonShowDetailsTableEditActivities { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities .messageDetailsTableEditActivities {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditActivities .tbodyTableEditActivities.finished .editTableEditActivities {
    text-decoration: line-through;

}
.tableEditActivities .tbodyTableEditActivities .acitiviesIconShowedup {
    cursor: pointer;
}