.cardResumeWithHover {
    width: 220px; /*DEFAULT: 220px*/
    height: 96px; /*DANGER!!! DEFAULT: 96px*/
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px var(--dark-dark-three);
    overflow: scroll;
    background-color: var(--white-light);
}

.cardResumeWithHover:hover {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    transition: box-shadow 0.8s;
}

.cardResumeWithHover .headCardResumeWithHover {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    position: relative;
    height: 40px;
}

.cardResumeWithHover .headCardResumeWithHover .iconCardResumeWithHover {
    font-size: 34px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    color: var(--dark-dark);
}

.cardResumeWithHover .headCardResumeWithHover .titleCardResumeWithRouter {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    color: var(--dark-dark);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.cardResumeWithHover .bodyCardResumeWithHover {
    /* nothing */
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter {
    margin-top: 5px;
    background-color: var(--dark-dark);
    padding: 4px 8px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.success {
    background-color: var(--color-success);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.error {
    background-color: var(--color-error);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.focus {
    background-color: var(--color-focus);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter .labelButtonCardResumeWithRouter {
    color: var(--white-dark);
    font-size: 12px;
    font-weight: 400;
}

.cardResumeWithHover .hoverContentBodyCardResumeWithRouter {
    /* nothing */
}