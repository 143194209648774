.customTooltipLineCharts {
    background-color: #fff;
    padding: 20px;
    padding-left: 30px;
    border-radius: 5px;
    min-width: 320px;
    min-height: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}

.customTooltipLineCharts .label {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 300;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .emptyCustomToolTipLineCharts {
    content: " ";
    display: block;
    min-width: 122px;
}


.customTooltipLineCharts .lineCustomTooltipLineCharts .title {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .title.success {
    color: var(--color-success);
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .title.information {
    color: var(--color-information);
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .amount {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .performanceCustomTooltipLineCharts {
    max-width: 120px;
}
