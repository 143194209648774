@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.messageAudioWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageAudioWhatsApp .buttonDefaultMessageAudioWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageAudioWhatsApp.right {
    justify-content: flex-end;
}

.messageAudioWhatsApp.left {
    justify-content: flex-start;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp {
    background-color: #d9fdd3;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp {
    background-color: #fff;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .iconDeletedMessageAudioWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.initial {
    display: block;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textMessageAudioWhatsApp {
    color: rgb(17, 27, 33);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textMessageAudioWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    font-family: SF_Pro_Text_Light;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textSpaceMessageAudioWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp {
    padding: 5px;
    padding: 5px 12px 7px 12px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-height: 42px;
    max-height: 82px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -3px;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.right {
    background-color: rgb(209, 244, 204);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.left {
    background-color: #f5f6f6;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .shadow {
    opacity: 1;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .textAnswerMessageAudioWhatsApp {
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 19px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .textAnswerMessageAudioWhatsApp.hasImage {
    width: calc(100% - 58px); /* width of the answer image */
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .nameAnswerMessageAudioWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .iconAnswerMessageAudioWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .innerImageAnswerMessageAudioWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .innerImageAnswerMessageAudioWhatsApp .imageInnerImageAnswerMessageAudioWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio {
    min-width: 50px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage {
    margin-left: 4px;
    margin-right: 12px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp {
    fill: rgba(84, 101, 111, 0.5);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp.right {
    fill: #6f8171;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp.left {
    fill: #9c8d8d;    
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .imageLoadingMessageAudioWhatsApp {
    width: 50px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioTwoMessageAudioWhatsApp {
    flex-direction: column;
    position: relative;
    margin-right: 19px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioTwoMessageAudioWhatsApp .audioPlayerMessageAudioWhatsApp {
    /* nothing yet */
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp {
    width: 100%;
    position: absolute;
    bottom: -21px;
    height: 40px;
    margin-bottom: -2px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration {
    font-size: 11px;
    color: #8696a0;
    font-weight: 400;
    display: flex;
    align-items: baseline;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp {
    margin-left: 3px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.sent {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.delivered {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.read {
    fill: #53bdeb;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.deleted {
    fill: #8696a0;
    height: 16px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .currentDurationAudioWhatsApp {
    float: left;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .maximumDurationAudioWhatsApp {
    float: right;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .maximumDurationAudioWhatsApp.right {
    margin-right: -16px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp {
    min-width: 55px; /* width of avatar component */
    position: relative;
    margin-left: 4px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .buttonSpeedMessageAudioWhatsApp {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    text-align: center;
    font-size: 13px;
    min-width: 48px;
    color: #fff;
    font-weight: 500;
    background-color: rgba(84,101,111,0.5);
    font-family: SF_Pro_Text_Light;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp {
    height: 55px;
    width: 55px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: auto;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp .imageAvatarMessageAudioWhatsApp {
    height: 100%;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp .imageDefaultAvatarMessageAudioWhatsApp {
    height: 55px;
    width: 55px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp {
    position: absolute;
    bottom: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.right {
    right: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.left {
    left: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.noListened {
    fill: rgba(17,27,33,0.7);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.listened {
    fill: #29afdf;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageAudioWhatsApp .areaMessageAudioWhatsApp.emphasis .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    display: none!important;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.right {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.answer {
    background: transparent;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.left.noAnswer {
    right: 68px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.answer {
    background-color: transparent;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp .innerDetailsTimeAndStatusMessageAudioWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp .innerDetailsTimeAndStatusMessageAudioWhatsApp .timeMessageAudioWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}