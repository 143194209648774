.serviceForm {
    position: relative;
    top: -23px;
    right: 32px;
}

.serviceForm .buttonContinueRegistrationServiceForm {
    background: var(--color-warning);
    font-size: 18px;
    border-radius: 2px;
    margin: 0;
    height: 50px;
    min-width: 285px;
    max-width: 285px;
    padding: 4px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
    border-radius: 2px;
}

.serviceForm .buttonIconSpacingServiceForm {
    margin-right: .5rem !important;
}