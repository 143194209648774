.stylizedMoneyOrNumber {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    min-width: 120px;
    display: inline-flex;
    justify-content: space-between;
}