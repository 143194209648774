.limitedDynamicTable {
    position: relative;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable {
    background-color: var(--white-dark-two);
    width: 100%;
    background-color: var(--white-dark-two);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 48px;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable.fixed {
    position: fixed;
    top: 55px;
    z-index: 100;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable.none{
    display: none;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.right {
    justify-content: end;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable span {
    color: var(--primary-color);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color); 
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable:hover .help {
    display: block;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

}

.limitedDynamicTable .lineBodyLimitedDynamicTable:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 30%;

    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 14px;


}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable span.click {
    cursor: pointer;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.right {
    justify-content: end;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable {
    height: 32px;
    width: 100%;

    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 48px;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.right {
    justify-content: end;
}