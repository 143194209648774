@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

:root {

    /* start colors */
    /* start colors */
    /* start colors */
    --primary-color: #26292c;
    
    --dark-dark: #353535;
    --dark-light: #949494;
    --dark-dark-two: #202225;
    --dark-dark-three: #696969;
    
    --white-light: #fff;
    --white-dark: #f7f7f7;
    --white-dark-two: #dcdddd;
    --white-dark-three: #f8f8f8;
    
    --color-error: #dc3545;
    --color-success: #28a745;
    --color-warning: #ef9602;
    --color-information: #007bff;
    --color-focus: #317ae2;
    --color-inactive: #787878;
    --color-purple: purple;
    --color-orange: #ff7400;
    --color-limon: #a6b916;
    --color-strawberry: #ff8f8f;
    --color-banana: #ffce00;
    --color-watermelon: #ff6666;
    /* ends colors */
    /* ends colors */
    /* ends colors */
    
    /* start dimensions */
    /* start dimensions */
    /* start dimensions */
    --whatsapp-width-chatlist: 430px; /*default is 430px*/
    --whatsapp-height-chatlist-header: 59px; /*default is 59px*/
    --whatsapp-width-point-audio-recording: 4px; /*default is 4px*/
    --whatsapp-margin-point-audio-recording: 2px; /*default is 2px*/
    --whatsapp-width-more-details-profile: 450px;
    --whatsapp-width-point-audio-recording-triple: calc(var(--whatsapp-width-point-audio-recording) * 3 + var(--whatsapp-margin-point-audio-recording)); /*DANGER: DON'T CHANGE NOTHING HERE!!!*/
    /* ends dimensions */
    /* ends dimensions */
    /* ends dimensions */

}

*{
    padding: 0;
    margin: 0;
    scrollbar-width: none;
}

body {  
    background-color: var(--white-dark);
    font-family: 'Source Sans Pro', sans-serif;
}

::-webkit-scrollbar {
    display: none;
}

button:focus-visible, button:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}