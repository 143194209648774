@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.messageTextWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp.image {
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .loadingWhatsApp {
    width: 74px;
    height: 74px;
    position: relative;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp {
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp .buttonTryingDownloadFileMessageTextWhatsApp {
    position: relative;
    padding: 0;
    background-color: rgba(11,20,26,.35);
    height: 50px;
    border-radius: 25px;
    padding-right: 18px;
    padding-left: 18px;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp .buttonTryingDownloadFileMessageTextWhatsApp .iconButtonTryingDownloadFileMessageTextWhatsApp {
    color: #fff;
}

.messageTextWhatsApp .buttonDefaultMessageTextWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageTextWhatsApp.right {
    justify-content: flex-end;
}

.messageTextWhatsApp.left {
    justify-content: flex-start;
}

.messageTextWhatsApp .areaMessageTextWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp {
    background-color: #d9fdd3;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp {
    background-color: #fff;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .iconDeletedMessageTextWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.initial {
    display: block;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textMessageTextWhatsApp {
    color: rgb(17, 27, 33);
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textMessageTextWhatsApp.deleted {
    color: #8696a0;
    text-decoration: line-through;
    /* font-style: italic; RESERVED */
    /* font-family: SF_Pro_Text_Light; RESERVED */
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textSpaceMessageTextWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp {
    padding: 5px 12px 7px 12px;
    min-height: 42px;
    max-height: 92px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.right {
    background-color: rgb(209, 244, 204);
    min-width: 150px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.left {
    background-color: #f5f6f6;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .shadow {
    opacity: 1;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp {
    width: calc(100% - 58px);
    min-width: 110px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .textAnswerMessageTextWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .textAnswerMessageTextWhatsApp .iconTypeAnswerMessageTextWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .nameAnswerMessageTextWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .innerImageAnswerMessageTextWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;    
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .innerImageAnswerMessageTextWhatsApp .imageInnerImageAnswerMessageTextWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 120px;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: -15px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageTextWhatsApp .areaMessageTextWhatsApp.emphasis .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    display: none!important;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp.sentForMe {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp.answer {
    background: transparent;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.answer {
    background-color: transparent;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .timeMessageTextWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp {
    margin-left: 3px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.sent {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.delivered {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.read {
    fill: #53bdeb;
}