.tableEditCampaign {
    background-color: var(--white-light);
    overflow: scroll;
}

.tableEditCampaign .helpTableEditCampaign {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
}

.tableEditCampaign table, tr, th, td {
    margin: 0;
}

.tableEditCampaign table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCampaign table .buttonCheckTableEditCampaign {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCampaign table .buttonCheckTableEditCampaign .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCampaign table .buttonCheckTableEditCampaign.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCampaign table .buttonCheckTableEditCampaign.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCampaign table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCampaign table tr {
    height: 32px;
}

.tableEditCampaign .theadTableEditCampaign td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCampaign .theadTableEditCampaign td.minimus {
    width: 90px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus45 {
    width: 30px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus60 {
    width: 60px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus75 {
    width: 75px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus100 {
    width: 100px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus120 {
    width: 120px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus140 {
    width: 140px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus180 {
    width: 180px!important;
}

.tableEditCampaign .theadTableEditCampaign td:last-of-type {
    border-right: none;
}

.tableEditCampaign .tbodyTableEditCampaign:hover {
    background-color: var(--white-dark);
}

.tableEditCampaign .tbodyTableEditCampaign:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCampaign td.checkTableEditCampaign {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCampaign .tbodyTableEditCampaign td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCampaign .table .tbodyTableEditCampaign td.checkTableEditCampaign {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCampaign .tbodyTableEditCampaign td:hover .buttonEditTableEditCampaign {
    opacity: 1;
}

.tableEditCampaign .tbodyTableEditCampaign td:hover .editTableEditCampaign {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCampaign .tbodyTableEditCampaign td .editTableEditCampaign.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCampaign .tbodyTableEditCampaign td .editTableEditCampaign .modalFieldEdit {
    z-index: 1000;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign {
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.noAction {
    cursor: default;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.success { 
    color: var(--color-success);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.error { 
    color: var(--color-error);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.warning { 
    color: var(--color-warning);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign .messageDetailsTableEditCampaign {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}