.menuRight {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    display: block;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);

    padding: 0 28px;
    margin-right: -392px;

    transition: margin ease .5s;
}

.menuRight.open {
    margin-right: 0;
}

.menuRight .menuRightHeader {
    height: 64px;
}

.menuRight .menuRightHeader h4 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.menuRight .buttonCloseMenuRight {
    color: var(--color-error);
    background: transparent;
    border: none;
    font-size: 24px;
}

.menuRight .buttonToggleMenuRight {
    position: absolute;
    left: -14px;
    top: 50%;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    font-size: 12px;
    background: #fff;
    box-shadow: -4px 0px 12px -5px var(--white-dark-two);
    border: none;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    z-index: 9999;
}

.menuRight .menuRightContent {
    height: calc(100vh - 64px);
    overflow: scroll;
    padding-bottom: 24px;
}