.debug {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.debug .warningDebug {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.debug .scrollDebug {
    overflow: scroll;
    height: 100%;
}

.debug .headerDebug {
    margin-top: 20px;
}

.debug .headerDebug h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.debug .headerDebug h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);

}

.debug .bodyDebug  {
    margin-bottom: 250px;
}

.debug .bodyDebug .boxDebug {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.debug .bodyDebug.error {
    background-color: var(--color-error);
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    text-align: center;
}

.debug .bodyDebug .boxDebug .lineDebug {
    width: 100%;
}

.debug .bodyDebug .boxDebug .lineDebug .columnDebug {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.debug .bodyDebug .boxDebug .lineDebug .columnDebug .inputStringSQLDebug .fieldInputGeneral {
    width: 100%;
}

.debug .bodyDebug .boxDebug.input {
    border: 2px solid var(--color-error);
}

.debug .bodyDebug .boxDebug.processing {
    border: 2px solid var(--color-warning);
}

.debug .bodyDebug .boxDebug.output {
    border: 2px solid var(--color-success);
}

.debug .bodyDebug .boxDebug .inputAttributeDebug  {
    max-width: 300px;
}

.debug .bodyDebug .boxDebug span {
    margin: 10px 20px;
}

.debug .bodyDebug .boxDebug .checkApplyTreatmentDebug .squareCheck {
    border-radius: 50%;
}

.debug .bodyDebug .buttonDebug {
    width: 180px;
    font-weight: 400;
    font-size: 16px;
    margin: 10px;
}

.debug .bodyDebug .buttonDebug.error {
    background-color: var(--color-error);
}

.debug .bodyDebug .boxDebug .treatmentOptionsDebug {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}