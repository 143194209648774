.audioRecordingWhatsApp {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-left: 4px;
}

.audioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.audioRecordingWhatsApp .durationAudiotRecordingWhatsApp {
    color: rgb(59, 74, 84);
    font-size: 20px;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp {
    max-width: 303px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .innerPointsAudioRecordingWhatsApp {
    display: flex;
    border: 1px solid transparent;
    margin-left: calc(var(--whatsapp-width-point-audio-recording-triple) * -1);
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .innerPointsAudioRecordingWhatsApp .pointRecordingAudioRecordingWhatsApp { /*width this poitn is: 12px */
    height: var(--whatsapp-width-point-audio-recording);
    width: var(--whatsapp-width-point-audio-recording);
    border-radius: 50%;
    background-color: rgb(132, 146, 155);
    content: " ";
    display: block;
    margin-left: var(--whatsapp-margin-point-audio-recording);
    margin-right: var(--whatsapp-margin-point-audio-recording);
    animation: point 1s linear infinite;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .lineAudioRecordingWhatsApp {
    display: block;
    content: " ";
    width: 100vw;
    height: 1px;
    background-color: rgb(132, 146, 155);
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    flex-direction: row;
    height: 46px;
    border-radius: 50px;
    background-color: #fff;
    margin: 0px 12px 0px 9px;
    padding: 0px 11px 0px 19px;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .durationAudioRecordingWhatsApp {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
    color: rgb(59, 74, 84);
    font-weight: 400;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 10px;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp .iconButtonDefaultAudioRecordingWhatsApp {
    fill: #667781;
}

@keyframes point {
	from {
		transform: translateX(calc(100% + (var(--whatsapp-width-point-audio-recording-triple))));
	}
	to {
		transform: translateX(calc(var(--whatsapp-width-point-audio-recording-triple) * -1));
	}
}