.leads {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.leads .warningLeads {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.leads .containerNavigationHeaderLeads {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:hover {
    background-color: var(--white-dark);
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.leads .containerNavigationHeaderLeads .buttonPlusNavigationHeaderLeads {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.leads .containerNavigationHeaderLeads .buttonTrashNavigationHeaderLeads {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .iconFilterFontsHeaderLeads {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads {
    cursor: pointer;
    width: 100%;
    border: none;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .fieldSelectMulti {
    border: none;
}

.leads .containerListHeaderLeads {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderLeads */
}