.progressLine {
    width: 100%;
}

.progressLine .lineProgressLine {
    height: 30px; /*DANGER: must be the same height*/
    border-radius: 4px;
}

.progressLine .lineProgressLine .stageContentProgressLine {
    width: 20%;
    height: 100%;
    position: relative;
    background-color: #dedede;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: var(--white-light);
}

.progressLine .lineProgressLine .stageContentProgressLine .infoStageContentProgressLine {
    display: none;
}

.progressLine .lineProgressLine .stageContentProgressLine:hover .infoStageContentProgressLine {
    background-color: black;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
    position: absolute;
    bottom: -28px;
    min-width: 120px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.progressLine .lineProgressLine .stageContentProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine {
    width: 6px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    top: 0px;
    left: 0;
    overflow: hidden;
    background-color: #dedede;
    /* color: var(--white-light);
    text-align: center; */
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine:after {
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    background-color: #dedede;
    width: 24px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    right: -6px;
    top: 2px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    transform: scaleX(.3) rotate(45deg);
    content: " ";
    cursor: pointer;
}

.progressLine .lineProgressLine .stageContentProgressLine.first .stageArrowProgressLine:after {
    border-top: 4px solid #08a742;
    border-right: 4px solid #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.active .stageArrowProgressLine.active:after {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.last .stageArrowProgressLine:after {
    background: #08a742;
}

.progressLine .lineDetailsProgressLine {
    display: inline-block;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.progressLine .lineDetailsProgressLine span {
    color: var(--primary0color);
    font-size: 12px;
    font-weight: 300;
}

.progressLine .lineDetailsProgressLine .iconProgressLine {
    font-size: 12px;
    color: var(--dark-light);
    margin: 0 5px;
}