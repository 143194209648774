.cardChartPie {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.cardChartPie .titleCardChartPie {
    width: 100%;
}

.cardChartPie .subtitleCardChartPie {
    width: 100%;
}

.cardChartPie .chartChartPie {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}

.cardChartPie .chartChartPie .labelOverCardChartPie {
    /* start alignment */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 135px;
    height: 135px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* ends alignment */
    
    /* start general style */
    border: 2px solid var(--color-success);
    border-style: dotted!important;
    border-radius: 50%;
    font-weight: 600;
    font-size: 32px;
    color: var(--color-success);
    /* ends general style */
}

.cardChartPie .chartChartPie .labelOverCardChartPie.success {
    border: 2px solid var(--color-success);
    color: var(--color-success);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.error {
    border: 2px solid var(--color-error);
    color: var(--color-error);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.warning {
    border: 2px solid var(--color-warning);
    color: var(--color-warning);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.information {
    border: 2px solid var(--color-information);
    color: var(--color-information);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.purple {
    border: 2px solid var(--color-purple);
    color: var(--color-purple);
}