.buttonWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0;
    padding-right: 58px;
    padding-left: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    position: relative;
    color: #3b4a54;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14.5px;
    height: 40px;
    font-weight: 400;
}

.buttonWhatsApp:hover {
    background-color: #f5f6f6;
}