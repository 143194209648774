.check {
    position: relative;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    text-align: left;
    color: #26292ca3;
    font-size: 15px;
    line-height: 20px;    
}

.check .squareCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
    border: 1px solid black;
    padding: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid #b9babb;
}

.check .checked {
    border: 2px solid green;
    background: green;
}

.check .iconCheck{
    color: #fff;
    font-size: 10px;
}