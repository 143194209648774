@font-face {
    font-family: SF_Pro_Text_Light;
    src: url("../../assets/fonts/SF-Pro-Text-Font-Family/SF-Pro-Text-Light.otf");
}

.messageImageWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageImageWhatsApp .areaLoadingMessageImageWhatsApp {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageImageWhatsApp .areaLoadingMessageImageWhatsApp .loadingWhatsApp {
    width: 74px;
    height: 74px;
    position: relative;
}

.messageImageWhatsApp .buttonDefaultMessageImageWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageImageWhatsApp.right {
    justify-content: flex-end;
}

.messageImageWhatsApp.left {
    justify-content: flex-start;
}

.messageImageWhatsApp .areaMessageImageWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp {
    background-color: #d9fdd3;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp {
    background-color: #fff;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .iconDeletedMessageImageWhatsApp {
    /* fill: rgba(17,27,33,.35); */
    fill: #fff;
    margin-right: 7px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.initial {
    display: block;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
    max-width: 250px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textMessageImageWhatsApp {
    color: rgb(17, 27, 33);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textMessageImageWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textSpaceMessageImageWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp {
    padding: 5px 12px 7px 12px;
    min-height: 42px;
    max-height: 92px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.right {
    background-color: rgb(209, 244, 204);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.left {
    background-color: #f5f6f6;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .shadow {
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp {
    width: calc(100% - 58px);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .textAnswerMessageImageWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .textAnswerMessageImageWhatsApp .iconAnswerMessageImageWhatsApp {
    fill: rgb(134, 150, 160);
    margin-right: 5px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .nameAnswerMessageImageWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .innerImageAnswerMessageImageWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .innerImageAnswerMessageImageWhatsApp .imageInnerImageAnswerMessageImageWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp {
    margin-top: -3px;
    margin-right: -4px;
    margin-bottom: 5px;
    margin-left: -6px;
    overflow: hidden;
    position: relative;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp.emphasis.right {
    background: #0000003f;
    transition: opacity .5s;
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp.emphasis.left {
    background: #0000003f;
    transition: opacity .5s;
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp.noMessage {
    margin-bottom: -5px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .imageMessageImageWhatsApp {
    border-radius: 7.5px;
    cursor: pointer;
    width: 100%;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageImageWhatsApp .areaMessageImageWhatsApp.emphasis .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    display: none!important;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.sentForMe {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.answer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.noAnswer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.answer {
    /* nothing */
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.noAnswer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
    background-color: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.noAnswer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp.noMessage {
    height: 20px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .timeMessageImageWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp.noMessage .innerDetailsTimeAndStatusMessageImageWhatsApp .timeMessageImageWhatsApp {
    color: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp {
    margin-left: 3px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.sent {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.delivered {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.read {
    fill: #53bdeb;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.deleted {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.deleted.noMessage {
    fill: #fff;
}