.tableEditCommissionReport {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReport .helpTableEditCommissionReport {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionReport .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReport table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReport table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReport table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReport table td.center {
    text-align: center;
}

.tableEditCommissionReport table td.left {
    text-align: left;
}

.tableEditCommissionReport table td.right {
    text-align: right;
}

.tableEditCommissionReport table tr {
    height: 32px;
}

.tableEditCommissionReport .theadTableEditCommissionReport td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus {
    width: 90px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td:last-of-type {
    border-right: none;
}

.tableEditCommissionReport .tbodyTableEditCommissionReport:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReport .tbodyTableEditCommissionReport:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReport .tbodyTableEditCommissionReport td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReport .tfootTableEditCommissionReport td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}