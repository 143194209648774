.business {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.business .warningBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.selectAttendantToTransferBusiness .optionsSelect { /*INFORMATION: component inside modal confirmation*/
    position: relative;
}

.lineMessagesWhatsAppBusiness { /* WARNING: Don't have reference with .Business, because is modal*/
    justify-content: space-between;
    display: flex;
}

.lineMessagesWhatsAppBusiness .buttonWhatsAppBusiness { /* WARNING: Don't have reference with .Business, because is modal*/
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
    max-width: 100px;
}

.lineMessagesWhatsAppBusiness .buttonWhatsAppBusiness.information { /* WARNING: Don't have reference with .Business, because is modal*/
    max-width: 140px;
    background-color: var(--color-focus);
}

textarea.fieldMessageWhatsAppDetailsBusiness { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    width: 100%;
    height: 198px;
}

.business .containerDraggableAreaHeaderBusiness {
    display: flex;
    flex-direction: row;
    height: 60px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines {  
    position: relative;
    width: 20%;
    background-color: var(--white-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: left;
    padding-left: 15px;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .titleColumnBusiness { 
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .informationColumnBusiness { 
    color:  var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .arrow{
    width: 9px;
    height: 55px;
    position: absolute;
    right: -4px;
    fill: var(--white-light);
    z-index: 600;
}

.business .containerExtraDraggableBusiness {
    position: relative;
}

.business .containerDraggableAreBusiness { 
    background-color: var(--white-dark);
    position: relative;
    flex-direction: row;
    display: flex;
    height: calc(100vh - 116px - 65px); /*56px by header component + 60px by containerDraggableAreaHeaderBusiness + 65px containerNavigationHeaderBusiness*/
    overflow: scroll;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness { 
    width: 20%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness.active {
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonWhatsappDraggableBusiness {
    color: var(--color-success);
    background-color: transparent;
    height: initial;
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .draggableBusiness { 
    width: 500px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness {
    opacity: 0; 
    background-color: transparent;
    margin: 5px 10px;
    height: 30px;
    border-radius: 5px;
    font-size: 18px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness:hover .buttonPlusDealDraggableBusiness {
    opacity: 1;
    color: var(--dark-light);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness:hover {
    background: rgba(38,41,44,.05);
    color: var(--primary-color);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness { 
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness {
    background-color: black;
    width: 32px!important;
    border-radius: 10px!important;
    height: 4px!important;
    overflow: hidden;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness .stampDraggableBusiness {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .titleDraggableBusiness {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .nameDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .lineDraggablesBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .lineDraggablesBusiness .moneyDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 600;
}

.business .containerDraggableAreaFooterBusiness {
    border: 1px solid var(--white-dark-two);
    background-color: var(--white-dark);
    height: 65px;
    position: absolute;
    bottom: -65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    z-index: 400;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness.active {
    bottom: 0px;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness {
    width: 25%;
    padding: 5px;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness {
    height: 100%;
    width: 100%;
    font-size: 16px;
    background-color: transparent;
    font-weight: 600;
    color: var(--dark-light);
    text-transform: uppercase;
    border: 1px dashed var(--dark-light);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness:hover {
    border: none;
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error {
    color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success {
    color: var(--color-success);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-success);
}

.business .containerNavigationHeaderBusiness {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:hover {
    background-color: var(--white-dark);
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.business .containerNavigationHeaderBusiness .buttonPlusNavigationHeaderBusiness {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.business .containerNavigationHeaderBusiness .buttonTrashNavigationHeaderBusiness {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .iconfilterStatusHeaderBusiness {
    font-size: 14px;
    color: var(--dark-dark);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness {
    cursor: pointer;
    width: 100%;
    border: none;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .textShowSelect,
.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .fieldSelect,
.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .fieldSelectMulti
{
    border: none;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness.selectAttendantsHeaderBusiness .optionsSelectMulti {
    width: auto;
}

.business .containerListHeaderBusiness {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderBusiness */
}

.business .modalMoreFiltersBusiness {
    position: absolute;
    max-width: 100vw;
    min-width: 550px;
    height: 340px;
    top: 35px;
    right: 120px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.business .modalMoreFiltersBusiness .lineBodyModalMoreFiltersBusiness {
    padding: 5px 13px;
}

.business .modalMoreFiltersBusiness .lineBodyModalMoreFiltersBusiness.featured {
    background-color: var(--white-dark);
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.business .buttonFilterDateBusiness {
    color: var(--primary-color);
    width: 100px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    height: 32px;
    padding: 0 10px;
}

.business .buttonFilterDateBusiness:nth-child(1) {
    border-right: 1px solid #26292c32;
    width: 120px;
}

.business .buttonFilterDateBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}